import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { SidebarObserverService } from '@shared/services/observer/sidebar-observer.service';
import { WindowStateObserverService } from '@shared/services/observer/window-state-observer.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { ThemeService } from '@shared/services/theme.service';
import { SidebarProp } from '@store/ux-states/sidebars/sidebars.state';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppStoreService } from './app.store.service';
import { AnalyticsService } from '@shared/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SidebarObserverService, SidebarService, AppStoreService],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('sidebarLeft')
  public sidebarLeft: MatSidenav;

  @ViewChild('sidebarRight')
  public sidebarRight: MatSidenav;

  public hasBackdrop$: Observable<boolean> = this.sidebarService.hasBackdrop$;

  public leftSidebar$: Observable<SidebarProp> = this.sidebarService.leftSidebar$;

  public rightSidebar$: Observable<SidebarProp> = this.sidebarService.rightSidebar$;

  public isMobile$: Observable<boolean> = this.appStoreService.isMobile$;

  public isMiddle$: Observable<boolean> = this.appStoreService.isMiddle$;

  public leftSidebarStyle$: Observable<{ [key: string]: string }>;

  public rightSidebarStyle$: Observable<{ [key: string]: string }>;

  public wrapperStyle$: Observable<{ [key: string]: string }>;

  public headerStyle$: Observable<{ [key: string]: string }>;

  constructor(
    private appStoreService: AppStoreService,
    public sidebarService: SidebarService,
    private changeDetectorRef: ChangeDetectorRef,
    private windowService: WindowStateObserverService,
    private translateService: TranslateService,
    private themeService: ThemeService,
    overlayContainer: OverlayContainer,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private readonly analyticsService: AnalyticsService,
  ) {
    // TODO: вынести инициализацию в APP_INITIALIZER
    this.translateService.use(this.translateService.getBrowserLang());
    this.windowService.initObserver().subscribe();
    this.headerStyle$ = this.createHeaderSizeObs();
    this.themeService.init(overlayContainer);
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this.addCustomIcons();

    this.analyticsService.init();
    
  }

  public ngAfterViewInit(): void {
    this.checkCookiesAgree();

    setTimeout(() => {
      this.sidebarService
        .initSidebars(this.sidebarLeft, this.sidebarRight)
        .subscribe(([leftSidebar$, rightSidebar$, wrapperStyle$]) => {
          this.leftSidebarStyle$ = leftSidebar$;
          this.rightSidebarStyle$ = rightSidebar$;
          this.wrapperStyle$ = wrapperStyle$.pipe(tap(() => this.changeDetectorRef.markForCheck()));
        });
    });
  }

  private checkCookiesAgree(): void {
    const cookiesAgreeLSName: string = 'cookiesAgree';
    if (!JSON.parse(localStorage.getItem(cookiesAgreeLSName))) {
      this.snackBar
        .open('Для правильной работы сайта и необходимых сервисов мы используем cookie', 'Понятно')
        .onAction()
        .subscribe(() => {
          localStorage.setItem(cookiesAgreeLSName, 'true');
        });
    }
  }

  private addCustomIcons(): void {
    this.matIconRegistry.addSvgIconInNamespace(
      'logos',
      'vk',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/vk-mono.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'logos',
      'discord',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/discord.svg'),
    );
  }

  private createHeaderSizeObs(): Observable<{ width: string }> {
    const headerSize = {
      full: { width: '100%' },
      mobile: { width: '100%' },
    };

    return this.isMiddle$.pipe(
      map((isMiddle) => {
        return !isMiddle ? headerSize.full : headerSize.mobile;
      }),
    );
  }
}
