import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { SearchRoutesEnum } from '@modules//search/search-routing.constants';
import { MyProfileRoutesEnum } from '@modules/my/my-routing.constants';
import { MyProfileRoutesService } from '@shared/services/my-profile-routes.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { SidebarProp } from '@store/ux-states/sidebars/sidebars.state';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LeftSidebarStoreService } from './left-sidebar.store.service';

interface Menuitem {
  icon: string;
  text: string;
  ngIfAsync: Observable<boolean>; // use of(true) if not need check
  src?: string;
  srcAsync?: Observable<string>;
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  providers: [LeftSidebarStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuComponent implements OnInit {
  public readonly leftSidebar$: Observable<SidebarProp> = this.leftSidebarStoreService.leftSidebar$;

  public readonly isMobile$: Observable<boolean> = this.leftSidebarStoreService.isMobile$;

  public readonly isLoggedIn$: Observable<boolean> = this.leftSidebarStoreService.isLoggedIn$;

  public readonly searchMenu: Menuitem[] = [];

  public readonly accountMenu: Menuitem[] = [];

  public readonly isSearchCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(
    private leftSidebarStoreService: LeftSidebarStoreService,
    private sidebarService: SidebarService,
    private myProfileRoutesService: MyProfileRoutesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.loadMenus();
    this.changeDetectorRef.reattach();
  }

  public openLeftSidebar(): void {
    const { isMobile, isMiddle } = this.leftSidebarStoreService;
    if (isMobile) {
      this.sidebarService.toggleLeft();
    } else if (isMiddle) {
      this.sidebarService.changeLeftSidebarViewState(true);
    }

    this.changeSearchCollapseState(true);
  }

  public closeSidebar(): void {
    this.sidebarService.closeLeft();
    this.changeSearchCollapseState(false);
  }

  public changeSearchCollapseState(status: boolean): void {
    this.isSearchCollapsed$.next(status);
  }

  private loadMenus(): void {
    this.accountMenu.push(
      {
        icon: 'face',
        srcAsync: this.myProfileRoutesService.myProfileLink$,
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.ACCOUNT_MENU.MY_PROFILE',
        ngIfAsync: this.isLoggedIn$,
      },
      {
        icon: 'person',
        src: MyProfileRoutesEnum.getFullLink(MyProfileRoutesEnum.chars),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.ACCOUNT_MENU.MY_CHARS',
        ngIfAsync: this.isLoggedIn$,
      },
      {
        icon: 'group',
        src: MyProfileRoutesEnum.getFullLink(MyProfileRoutesEnum.comms),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.ACCOUNT_MENU.MY_COMMS',
        ngIfAsync: this.isLoggedIn$,
      },
      {
        icon: 'event_seat',
        src: MyProfileRoutesEnum.getFullLink(MyProfileRoutesEnum.guilds),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.ACCOUNT_MENU.MY_GUILDS',
        ngIfAsync: this.isLoggedIn$,
      },
    );
    this.searchMenu.push(
      {
        icon: 'face',
        src: SearchRoutesEnum.getFullLink(SearchRoutesEnum.users),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.SEARCH_MENU.USERS',
        ngIfAsync: of(true),
      },
      {
        icon: 'person',
        src: SearchRoutesEnum.getFullLink(SearchRoutesEnum.chars),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.SEARCH_MENU.CHARS',
        ngIfAsync: of(true),
      },
      {
        icon: 'group',
        src: SearchRoutesEnum.getFullLink(SearchRoutesEnum.comms),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.SEARCH_MENU.COMMS',
        ngIfAsync: of(true),
      },
      {
        icon: 'event_seat',
        src: SearchRoutesEnum.getFullLink(SearchRoutesEnum.guilds),
        text: 'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.SEARCH_MENU.GUILDS',
        ngIfAsync: of(true),
      },
    );
  }
}
