import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from '@components/welcome/welcome.component';
import { AUTH_ROUTE } from '@modules/auth/auth-routing.constants';
import { DOCS_ROUTE } from '@modules/docs/docs-routing.constants';
import { MY_ROUTE } from '@modules/my/my-routing.constants';
import { SEARCH_ROUTE } from '@modules/search/search-routing.constants';
import { OtherRouteEnum, UnitRouteEnum } from '@shared/constants/app-routing.constants';
import { AuthGuard } from '@shared/guards/auth.guard';
import { MY_DATA_PARAM } from '@shared/constants/common-routing.constants';
import { SETTINGS_ROUTE } from '@modules/settings/settings-routing.constants';
import { routes as MyRoutes } from '@modules/my/my-routing.module';

const UNITS: Routes = [
  {
    path: UnitRouteEnum.char,
    loadChildren: () =>
      import('@modules/units/char/char-unit.module').then((m) => m.CharUnitModule),
  },
  {
    path: UnitRouteEnum.comm,
    loadChildren: () =>
      import('@modules/units/comm/comm-unit.module').then((m) => m.CommUnitModule),
  },
  {
    path: UnitRouteEnum.guild,
    loadChildren: () =>
      import('@modules/units/guild/guild-unit.module').then((m) => m.GuildUnitModule),
  },
  {
    path: UnitRouteEnum.user,
    loadChildren: () =>
      import('@modules/units/user/user-profile-unit.module').then((m) => m.UserProfileUnitModule),
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: OtherRouteEnum.welcome,
    pathMatch: 'full',
  },
  {
    path: OtherRouteEnum.welcome,
    component: WelcomeComponent,
  },
  {
    path: SETTINGS_ROUTE,
    data: { [MY_DATA_PARAM]: true },
    loadChildren: () => import('@modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: SEARCH_ROUTE,
    loadChildren: () => import('@modules/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: AUTH_ROUTE,
    loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: MY_ROUTE,
    children: MyRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: DOCS_ROUTE,
    loadChildren: () => import('@modules/docs/docs.module').then((m) => m.DocsModule),
  },
  ...UNITS,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
