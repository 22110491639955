export const SEARCH_ROUTE: string = 'search';

export enum SearchRoutesEnum {
  users = 'users',
  chars = 'chars',
  comms = 'comms',
  guilds = 'guilds',
}

export namespace SearchRoutesEnum {
  export function getFullLink(route: SearchRoutesEnum): string {
    return `/${SEARCH_ROUTE}/${route}`;
  }
}
