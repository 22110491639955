import { Injectable } from '@angular/core';
import { MyUnitSDKService } from '@clanhall-sdk/api/myUnit.sdk.service';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { CommsUnitsActions } from '@store/common-states/units/comms/comms-units.actions';
import { BaseUnitsState } from '@store/common-states/units/shared/base/base-units.state';
import {
  BaseUnitStateModelWithLists,
  getBaseUnitModel,
  getBaseUnitsLists,
} from '@store/common-states/units/shared/base/base-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import { CommSDKService } from '@clanhall-sdk/api/comm.sdk.service';
import { MyCommSDKService } from '@clanhall-sdk/api/myComm.sdk.service';
import { CommInListSDKModel } from '@clanhall-sdk/model/commInList.sdk.model';
import { Observable } from 'rxjs';

export interface CommsUnitsStateModel
  extends BaseUnitStateModelWithLists<CommInListSDKModel, TableParameterSDKModel> {}

const defaults: CommsUnitsStateModel = {
  ...getBaseUnitModel(),
  ...getBaseUnitsLists(),
};

@State<CommsUnitsStateModel>({
  name: 'comms',
  defaults,
})
@Injectable()
export class CommsUnitsState extends BaseUnitsState {
  private titles = {
    getMyUnits: 'Запрашиваем твои сообщества',
    loadUnit: 'Загружаем сообщество',
    saveUnit: 'Сохраняем сообщество',
    addUnit: 'Создаем сообщество',
    deleteUnit: 'Удаляем сообщество',
    restoreUnit: 'Восстанавливаем сообщество',
    getMyArchivedUnits: 'Запрашиваем твоих архивных сообществ',
  };

  constructor(
    private store: Store,
    private myCommSDKService: MyCommSDKService,
    private commSDKService: CommSDKService,
    protected myUnitSDKService: MyUnitSDKService,
  ) {
    super(myUnitSDKService);
  }

  @Selector()
  static loading(state: CommsUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: CommsUnitsStateModel) {
    return state.loadedUnits;
  }

  static unit(id: number) {
    return createSelector([CommsUnitsState], (state: CommsUnitsStateModel) => {
      return state.loadedUnits[id];
    });
  }

  @Selector()
  static myUnitsList(state: CommsUnitsStateModel) {
    return state.unitsList;
  }

  @Selector()
  static myUnitsIds(state: CommsUnitsStateModel) {
    return this.filterOnlyMyIds(state.loadedUnits);
  }

  @Selector()
  static listParams(state: CommsUnitsStateModel) {
    return state.unitsListParams;
  }

  @Selector()
  static myArchivedUnitsList(state: CommsUnitsStateModel) {
    return state.archivedUnitsList;
  }

  @Selector()
  static myArchivedUnitsListParams(state: CommsUnitsStateModel) {
    return state.archivedUnitsListParams;
  }

  @Action(CommsUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<CommsUnitsStateModel>): Observable<void> {
    return this.loadingStartBase(context as any);
  }

  @Action(CommsUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<CommsUnitsStateModel>): Observable<void> {
    return this.loadingEndBase(context as any);
  }

  @Action(CommsUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<CommsUnitsStateModel>,
    { title }: CommsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return this.changeTitleBase(context as any, title);
  }

  @Action(CommsUnitsActions.SaveUnitParams)
  public saveUnitParams(
    context: StateContext<CommsUnitsStateModel>,
    payload: CommsUnitsActions.SaveUnitParams,
  ): Observable<void> {
    return this.saveUnitParamsBase(
      context as any,
      payload,
      'comm',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(CommsUnitsActions.SaveUnitAvatar)
  public saveUnitAvatar(
    context: StateContext<CommsUnitsStateModel>,
    payload: CommsUnitsActions.SaveUnitAvatar,
  ): Observable<void> {
    return this.saveUnitAvatarBase(
      context as any,
      payload,
      'comm',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(CommsUnitsActions.DeleteUnit)
  public deleteUnit(
    context: StateContext<CommsUnitsStateModel>,
    payload: CommsUnitsActions.DeleteUnit,
  ): Observable<void> {
    return this.deleteUnitBase(
      context,
      payload,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.deleteUnit,
      () => this.myCommSDKService.deleteMyCommProfile({ id: payload.unitId }),
    );
  }

  @Action(CommsUnitsActions.RestoreUnit)
  public restoreUnit(
    context: StateContext<CommsUnitsStateModel>,
    payload: CommsUnitsActions.RestoreUnit,
  ): Observable<void> {
    return this.restoreUnitBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.restoreUnit,
      () => this.myCommSDKService.restoreMyCommProfile({ id: payload.unitId }),
    );
  }

  @Action(CommsUnitsActions.QueryMyUnitsList)
  public queryMyUnitsList(context: StateContext<CommsUnitsStateModel>): Observable<void> {
    return this.queryMyUnitsListBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyUnits,
      () => this.myCommSDKService.returnMyCommsList(),
    );
  }

  @Action(CommsUnitsActions.QueryUnitById)
  public queryUnitById(
    context: StateContext<CommsUnitsStateModel>,
    payload: CommsUnitsActions.QueryUnitById,
  ): Observable<void> {
    return this.queryUnitByIdBase(
      context as any,
      payload.unitId,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.loadUnit,
      () => this.commSDKService.returnCommProfile({ id: payload.unitId }),
    );
  }

  @Action(CommsUnitsActions.QueryMyArchivedUnitsList)
  public queryMyArchivedUnitsList(context: StateContext<CommsUnitsStateModel>): Observable<void> {
    return this.queryMyArchivedUnitsListBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyArchivedUnits,
      () => this.myCommSDKService.returnMyArchiveCommList(),
    );
  }

  @Action(CommsUnitsActions.AddMyUnit)
  public addMyUnit(
    context: StateContext<CommsUnitsStateModel>,
    { params }: CommsUnitsActions.AddMyUnit,
  ): Observable<void> {
    return this.addMyUnitBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.addUnit,
      () => this.myCommSDKService.addComm(params),
    );
  }
}
