/**
 * ClanHall API
 * ClanHall API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: admin@clanhall.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AddParamsSDKModel } from '../model/models';
import { Response401SDKModel } from '../model/models';
import { Response403SDKModel } from '../model/models';
import { Response404SDKModel } from '../model/models';
import { ReturnChildParamsWithOptionsResponseSDKModel } from '../model/models';
import { ReturnSearchChildParamsWithOptionsResponseSDKModel } from '../model/models';
import { SearchParameterSDKModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ReturnAddParamsRequestParams {
    gameId: number;
    unitType: 'user' | 'comm' | 'char' | 'guild';
}

export interface ReturnChildParamsWithOptionsRequestParams {
    paramId: number;
    paramValue: string;
}

export interface ReturnSearchChildParamsWithOptionsRequestParams {
    paramId: number;
    paramValue: string;
}

export interface ReturnUnitParamsWithOptionsRequestParams {
    gameId: number;
    unitType: 'char' | 'guild';
}


@Injectable({
  providedIn: 'root'
})
export class UnitSDKService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Ready
     * Returns unit params on add
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnAddParams(requestParameters: ReturnAddParamsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AddParamsSDKModel>;
    public returnAddParams(requestParameters: ReturnAddParamsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AddParamsSDKModel>>;
    public returnAddParams(requestParameters: ReturnAddParamsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AddParamsSDKModel>>;
    public returnAddParams(requestParameters: ReturnAddParamsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const gameId = requestParameters.gameId;
        if (gameId === null || gameId === undefined) {
            throw new Error('Required parameter gameId was null or undefined when calling returnAddParams.');
        }
        const unitType = requestParameters.unitType;
        if (unitType === null || unitType === undefined) {
            throw new Error('Required parameter unitType was null or undefined when calling returnAddParams.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameId !== undefined && gameId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameId, 'game_id');
        }
        if (unitType !== undefined && unitType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unitType, 'unit_type');
        }

        let headers = this.defaultHeaders;

        // authentication (JWTAuth) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["JWTAuth"] || this.configuration.apiKeys["Authorization"];
            if (key) {
                headers = headers.set('Authorization', key);
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AddParamsSDKModel>(`${this.configuration.basePath}/api/params/add_params`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnChildParamsWithOptions(requestParameters: ReturnChildParamsWithOptionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ReturnChildParamsWithOptionsResponseSDKModel>>;
    public returnChildParamsWithOptions(requestParameters: ReturnChildParamsWithOptionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ReturnChildParamsWithOptionsResponseSDKModel>>>;
    public returnChildParamsWithOptions(requestParameters: ReturnChildParamsWithOptionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ReturnChildParamsWithOptionsResponseSDKModel>>>;
    public returnChildParamsWithOptions(requestParameters: ReturnChildParamsWithOptionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const paramId = requestParameters.paramId;
        if (paramId === null || paramId === undefined) {
            throw new Error('Required parameter paramId was null or undefined when calling returnChildParamsWithOptions.');
        }
        const paramValue = requestParameters.paramValue;
        if (paramValue === null || paramValue === undefined) {
            throw new Error('Required parameter paramValue was null or undefined when calling returnChildParamsWithOptions.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (paramId !== undefined && paramId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paramId, 'param_id');
        }
        if (paramValue !== undefined && paramValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paramValue, 'param_value');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ReturnChildParamsWithOptionsResponseSDKModel>>(`${this.configuration.basePath}/api/params/child_params`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnSearchChildParamsWithOptions(requestParameters: ReturnSearchChildParamsWithOptionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ReturnSearchChildParamsWithOptionsResponseSDKModel>>;
    public returnSearchChildParamsWithOptions(requestParameters: ReturnSearchChildParamsWithOptionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ReturnSearchChildParamsWithOptionsResponseSDKModel>>>;
    public returnSearchChildParamsWithOptions(requestParameters: ReturnSearchChildParamsWithOptionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ReturnSearchChildParamsWithOptionsResponseSDKModel>>>;
    public returnSearchChildParamsWithOptions(requestParameters: ReturnSearchChildParamsWithOptionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const paramId = requestParameters.paramId;
        if (paramId === null || paramId === undefined) {
            throw new Error('Required parameter paramId was null or undefined when calling returnSearchChildParamsWithOptions.');
        }
        const paramValue = requestParameters.paramValue;
        if (paramValue === null || paramValue === undefined) {
            throw new Error('Required parameter paramValue was null or undefined when calling returnSearchChildParamsWithOptions.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (paramId !== undefined && paramId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paramId, 'param_id');
        }
        if (paramValue !== undefined && paramValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paramValue, 'param_value');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ReturnSearchChildParamsWithOptionsResponseSDKModel>>(`${this.configuration.basePath}/api/search/child_params`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ready
     * Returns list of Unit Parameters for Search
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnUnitParamsWithOptions(requestParameters: ReturnUnitParamsWithOptionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SearchParameterSDKModel>>;
    public returnUnitParamsWithOptions(requestParameters: ReturnUnitParamsWithOptionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SearchParameterSDKModel>>>;
    public returnUnitParamsWithOptions(requestParameters: ReturnUnitParamsWithOptionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SearchParameterSDKModel>>>;
    public returnUnitParamsWithOptions(requestParameters: ReturnUnitParamsWithOptionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const gameId = requestParameters.gameId;
        if (gameId === null || gameId === undefined) {
            throw new Error('Required parameter gameId was null or undefined when calling returnUnitParamsWithOptions.');
        }
        const unitType = requestParameters.unitType;
        if (unitType === null || unitType === undefined) {
            throw new Error('Required parameter unitType was null or undefined when calling returnUnitParamsWithOptions.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameId !== undefined && gameId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameId, 'game_id');
        }
        if (unitType !== undefined && unitType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unitType, 'unit_type');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<SearchParameterSDKModel>>(`${this.configuration.basePath}/api/search/game_child_params`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
