import { Injectable } from '@angular/core';
import { GameSDKService } from '@clanhall-sdk/api/game.sdk.service';
import { MyUnitSDKService } from '@clanhall-sdk/api/myUnit.sdk.service';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { GuildsUnitsActions } from '@store/common-states/units/guilds/guilds-units.actions';
import { BaseUnitsState } from '@store/common-states/units/shared/base/base-units.state';
import {
  BaseUnitStateModelWithLists,
  getBaseUnitModel,
  getBaseUnitsLists,
} from '@store/common-states/units/shared/base/base-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import { GuildSDKService } from '@clanhall-sdk/api/guild.sdk.service';
import { MyGuildSDKService } from '@clanhall-sdk/api/myGuild.sdk.service';
import { GuildInListSDKModel } from '@clanhall-sdk/model/guildInList.sdk.model';
import {
  CharListByGameResponseSDKModel,
  CharListByGameSDKModel,
  TableParameterSDKModel,
} from '@clanhall-sdk/model/models';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMapTo, tap } from 'rxjs/operators';

export interface GuildsUnitsStateModel
  extends BaseUnitStateModelWithLists<GuildInListSDKModel, TableParameterSDKModel> {
  availableCharsForGameCreate: {
    [gameId: string]: CharListByGameSDKModel[];
  };
  availableCharsForGuildRestore: CharListByGameSDKModel[];
  availableGames: { id: number; name: string }[];
}

const defaults: GuildsUnitsStateModel = {
  ...getBaseUnitModel(),
  ...getBaseUnitsLists(),
  availableCharsForGuildRestore: [],
  availableCharsForGameCreate: {},
  availableGames: [],
};

@Injectable()
@State<GuildsUnitsStateModel>({
  name: 'guilds',
  defaults,
})
export class GuildsUnitsState extends BaseUnitsState {
  private titles = {
    getMyUnits: 'Запрашиваем твои гильдии',
    loadUnit: 'Загружаем гильдию',
    saveUnit: 'Сохраняем гильдию',
    addUnit: 'Создаем гильдию',
    deleteUnit: 'Удаляем гильдию',
    restoreUnit: 'Восстанавливаем гильдию',
    getCharsForGuildRestore: 'Запрашиваем ваших персонажей для гильдии',
    getMyUnit: 'Запрашиваем твои гильдии',
    getMyArchivedUnits: 'Запрашиваем твои архивные гильдии',
  };

  constructor(
    private store: Store,
    private myGuildSDKService: MyGuildSDKService,
    private guildSDKService: GuildSDKService,
    private gameSDKService: GameSDKService,
    protected myUnitSDKService: MyUnitSDKService,
  ) {
    super(myUnitSDKService);
  }

  @Selector()
  static loading(state: GuildsUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: GuildsUnitsStateModel) {
    return state.loadedUnits;
  }

  static unit(id: number) {
    return createSelector([GuildsUnitsState], (state: GuildsUnitsStateModel) => {
      return state.loadedUnits[id];
    });
  }

  @Selector()
  static myUnitsList(state: GuildsUnitsStateModel) {
    return state.unitsList;
  }

  @Selector()
  static myUnitsIds(state: GuildsUnitsStateModel) {
    return this.filterOnlyMyIds(state.loadedUnits);
  }

  @Selector()
  static listParams(state: GuildsUnitsStateModel) {
    return state.unitsListParams;
  }

  @Selector()
  static myArchivedUnitsListParams(state: GuildsUnitsStateModel) {
    return state.archivedUnitsListParams;
  }

  @Selector()
  static myArchivedUnitsList(state: GuildsUnitsStateModel) {
    return state.archivedUnitsList;
  }

  @Selector()
  static availableGamesForCreate(state: GuildsUnitsStateModel): { id: number; name: string }[] {
    return state.availableGames;
  }

  static availableCharsForCreate(
    gameId: number,
  ): (state: GuildsUnitsStateModel) => CharListByGameSDKModel[] {
    return createSelector([GuildsUnitsState], (state: GuildsUnitsStateModel) => {
      return state.availableCharsForGameCreate[gameId];
    });
  }

  @Selector()
  static availableCharsForGuildRestore(state: GuildsUnitsStateModel): CharListByGameSDKModel[] {
    return state.availableCharsForGuildRestore;
  }

  @Action(GuildsUnitsActions.LoadingStart)
  public loadingStart(ctx: StateContext<GuildsUnitsStateModel>): Observable<void> {
    return this.loadingStartBase(ctx as any);
  }

  @Action(GuildsUnitsActions.LoadingEnd)
  public loadingEnd(ctx: StateContext<GuildsUnitsStateModel>): Observable<void> {
    return this.loadingEndBase(ctx as any);
  }

  @Action(GuildsUnitsActions.ChangeTitle)
  public changeTitle(
    ctx: StateContext<GuildsUnitsStateModel>,
    { title }: GuildsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return this.changeTitleBase(ctx as any, title);
  }

  @Action(GuildsUnitsActions.QueryMyArchivedUnitsList)
  public queryMyArchivedUnitsList(context: StateContext<GuildsUnitsStateModel>): Observable<void> {
    return this.queryMyArchivedUnitsListBase(
      context as any,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyArchivedUnits,
      () => this.myGuildSDKService.returnMyArchiveGuildList(),
    );
  }

  @Action(GuildsUnitsActions.SaveUnitParams)
  public saveUnitParams(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.SaveUnitParams,
  ): Observable<void> {
    return this.saveUnitParamsBase(
      context as any,
      payload,
      'guild',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(GuildsUnitsActions.SaveUnitAvatar)
  public saveUnitAvatar(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.SaveUnitAvatar,
  ): Observable<void> {
    return this.saveUnitAvatarBase(
      context as any,
      payload,
      'guild',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(GuildsUnitsActions.DeleteUnit)
  public deleteUnit(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.DeleteUnit,
  ): Observable<void> {
    return this.deleteUnitBase(
      context as any,
      payload,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.deleteUnit,
      () => this.myGuildSDKService.deleteMyGuildProfile({ id: payload.unitId }),
    );
  }

  @Action(GuildsUnitsActions.RestoreUnit)
  public restoreUnit(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.RestoreUnit,
  ): Observable<void> {
    return this.restoreUnitBase(
      context as any,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.restoreUnit,
      () =>
        this.myGuildSDKService.restoreMyGuildProfile({
          id: payload.unitId,
          restoreGuildRequestSDKModel: { charId: payload.charId },
        }),
    );
  }

  @Action(GuildsUnitsActions.GetCharsForGuildRestore)
  public getCharsForGuildRestore(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.GetCharsForGuildRestore,
  ): Observable<void> {
    return defer(() => {
      const currentTitle: string = this.store.selectSnapshot(HeaderState.title);

      this.changeTitleBase(context as any, this.titles.getCharsForGuildRestore);
      this.loadingStartBase(context as any);

      return this.myGuildSDKService.returnMyCharListByGuild({ guildId: payload.guildId }).pipe(
        tap((chars: CharListByGameResponseSDKModel) => {
          context.patchState({
            availableCharsForGuildRestore: chars.units,
          });
        }),
        finalize(() => {
          this.changeTitleBase(context as any, currentTitle);
          this.loadingEndBase(context as any);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  @Action(GuildsUnitsActions.QueryMyUnitsList)
  public queryMyUnitsList(context: StateContext<GuildsUnitsStateModel>): Observable<void> {
    return this.queryMyUnitsListBase(
      context as any,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyUnits,
      () => this.myGuildSDKService.returnMyGuildsList(),
    );
  }

  @Action(GuildsUnitsActions.AddMyUnit)
  public addMyUnit(
    context: StateContext<GuildsUnitsStateModel>,
    { params }: GuildsUnitsActions.AddMyUnit,
  ): Observable<void> {
    return this.addMyUnitBase(
      context as any,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.addUnit,
      () => this.myGuildSDKService.addGuild(params),
    );
  }

  @Action(GuildsUnitsActions.QueryUnitById)
  public queryUnitById(
    context: StateContext<GuildsUnitsStateModel>,
    payload: GuildsUnitsActions.QueryUnitById,
  ): Observable<void> {
    return this.queryUnitByIdBase(
      context as any,
      payload.unitId,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.loadUnit,
      () => this.guildSDKService.returnGuildProfile({ id: payload.unitId }),
    );
  }

  @Action(GuildsUnitsActions.GetAvailableCharsForCreate)
  public getAvailableCharsForCreate(
    { getState, patchState, dispatch }: StateContext<GuildsUnitsStateModel>,
    { params }: GuildsUnitsActions.GetAvailableCharsForCreate,
  ): Observable<void> {
    return defer(() => {
      dispatch(new GuildsUnitsActions.LoadingStart());
      return this.myGuildSDKService.returnMyCharListByGame(params).pipe(
        finalize(() => dispatch(new GuildsUnitsActions.LoadingEnd())),
        tap((response) => {
          patchState({
            availableCharsForGameCreate: {
              ...getState().availableCharsForGameCreate,
              [params.gameId]: response.units,
            },
          });
        }),
        catchError((error) => {
          return throwError(error);
        }),
        map(() => undefined),
      );
    });
  }

  @Action(GuildsUnitsActions.GetAvailableGamesForCreate)
  public getAvailableGames({
    patchState,
    dispatch,
  }: StateContext<GuildsUnitsStateModel>): Observable<void> {
    return defer(() => {
      dispatch(new GuildsUnitsActions.LoadingStart());
      return this.gameSDKService.returnGameList().pipe(
        finalize(() => dispatch(new GuildsUnitsActions.LoadingEnd())),
        tap((response) => {
          patchState({
            availableGames: response.games,
          });
        }),
        catchError((error) => {
          return throwError(error);
        }),
        map(() => undefined),
      );
    });
  }
}
