import { PageEvent } from '@angular/material/paginator';
import {
  ReturnCharListRequestParams,
  SearchCharsRequestParams,
} from '@clanhall-sdk/api/char.sdk.service';
import {
  ReturnCommListRequestParams,
  SearchCommsRequestParams,
} from '@clanhall-sdk/api/comm.sdk.service';
import {
  ReturnGuildListRequestParams,
  SearchGuildsRequestParams,
} from '@clanhall-sdk/api/guild.sdk.service';
import {
  ReturnUserProfileListRequestParams,
  SearchUserProfilesRequestParams,
} from '@clanhall-sdk/api/user.sdk.service';
import {
  CharListResponseSDKModel,
  CharListSDKModel,
  CharSearchResponseSDKModel,
  CommListResponseSDKModel,
  CommListSDKModel,
  CommSearchResponseSDKModel,
  GuildListResponseSDKModel,
  GuildListSDKModel,
  GuildSearchResponseSDKModel,
  UnitParameterSDKModel,
  UserProfileListResponseSDKModel,
  UserProfileListSDKModel,
  UserProfileSearchResponseSDKModel,
} from '@clanhall-sdk/model/models';
import { SearchParameterSDKModel } from '@clanhall-sdk/model/searchParameter.sdk.model';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { CharsSearchUnitsStateModel } from '@store/common-states/search/chars/search-chars-units.state';
import { CommsSearchUnitsStateModel } from '@store/common-states/search/comms/search-comms-units.state';
import { GuildsSearchUnitsStateModel } from '@store/common-states/search/guilds/search-guilds-units.state';
import { SearchUnitsBase } from '@store/common-states/search/shared/base/shared-search-units.actions';
import { UsersSearchUnitsStateModel } from '@store/common-states/search/users/search-users-units.state';
import { Header } from '@store/ux-states/header/header.actions';
import { camelCase } from 'lodash-es';
import { defer, Observable } from 'rxjs';
import { finalize, switchMapTo, tap } from 'rxjs/operators';

export interface UserSelectSearchParameterSDKModel extends SearchParameterSDKModel {
  userSelect?: string | number;
}

export interface BasicSearchUnitStateModel {
  loading: boolean;
  loadedUnits: {
    [key: number]: UnitParameterSDKModel[];
  };
  tableParams: Omit<
    UserProfileListSDKModel | CharListSDKModel | GuildListSDKModel | CommListSDKModel,
    'data'
  >;
  tableDataParams: TableParameterSDKModel[];
  searchParams: UserSelectSearchParameterSDKModel[];
}

const STATE_FORM_HELPER_DEFAULTS: BasicSearchUnitStateModel = {
  loading: false,
  loadedUnits: {},
  tableDataParams: [],
  searchParams: [],
  tableParams: {
    currentPage: 1,
    firstPageUrl: undefined,
    from: undefined,
    lastPage: undefined,
    lastPageUrl: undefined,
    nextPageUrl: undefined,
    path: undefined,
    perPage: 10,
    prevPageUrl: undefined,
    to: undefined,
    total: undefined,
  },
};

type AnyUnitStateModel = any;
// | UsersSearchUnitsStateModel
// | CommsSearchUnitsStateModel
// | GuildsSearchUnitsStateModel
// | CharsSearchUnitsStateModel;

export function getBasicSearchUnitStateModelDefaults(): BasicSearchUnitStateModel {
  return JSON.parse(JSON.stringify(STATE_FORM_HELPER_DEFAULTS));
}

export abstract class BaseSearchUnitsState {
  protected abstract titles: { [key: string]: string };

  protected basicTitles = {
    querySearchParams: 'Запрашиваю параметры поиска',
    search: 'Поиск',
  };

  public abstract loadingStart(context: StateContext<AnyUnitStateModel>): Observable<void>;

  public loadingStartBase(context: StateContext<AnyUnitStateModel>) {
    context.patchState({ loading: true });
    return context.dispatch(new Header.TurnOnLoading());
  }

  public abstract loadingEnd(context: StateContext<AnyUnitStateModel>): Observable<void>;

  public loadingEndBase(context: StateContext<AnyUnitStateModel>) {
    context.patchState({ loading: false });
    return context.dispatch(new Header.TurnOffLoading());
  }

  public abstract changeTitle(
    context: StateContext<AnyUnitStateModel>,
    payload: SearchUnitsBase.ChangeTitle,
  ): Observable<void>;

  public changeTitleBase(
    context: StateContext<AnyUnitStateModel>,
    title: string,
  ): Observable<void> {
    return context.dispatch(new Header.ChangeTitle(title));
  }

  public abstract updatePage(
    context: StateContext<AnyUnitStateModel>,
    payload: SearchUnitsBase.UpdatePage,
  ): Observable<void>;

  public updatePageBase(
    context: StateContext<AnyUnitStateModel>,
    { page }: SearchUnitsBase.UpdatePage,
    searchAction: SearchUnitsBase.Search,
  ): Observable<void> {
    context.patchState({
      tableParams: {
        ...context.getState().tableParams,
        ...{
          currentPage: page.pageIndex + 1,
          perPage: page.pageSize,
        },
      },
    });
    return context.dispatch(searchAction);
  }

  public abstract resetSearch({ dispatch }: StateContext<AnyUnitStateModel>);

  public abstract search(
    { getState, patchState, dispatch }: StateContext<AnyUnitStateModel>,
    {
      searchParams,
      page,
    }: {
      searchParams: Pick<UserSelectSearchParameterSDKModel, 'userSelect' | 'key'>[];
      page: PageEvent;
    },
  );

  public searchBase(
    context: StateContext<AnyUnitStateModel>,
    { searchParams, pageIndex }: SearchUnitsBase.Search,
    query:
      | ((requestParameters: SearchCharsRequestParams) => Observable<CharSearchResponseSDKModel>)
      | ((
          requestParameters: SearchUserProfilesRequestParams,
        ) => Observable<UserProfileSearchResponseSDKModel>)
      | ((requestParameters: SearchCommsRequestParams) => Observable<CommSearchResponseSDKModel>)
      | ((requestParameters: SearchGuildsRequestParams) => Observable<GuildSearchResponseSDKModel>),
    currentTitle: string,
  ): Observable<void> {
    if (searchParams && searchParams.length > 0) {
      context.patchState({
        searchParams: context.getState().searchParams.map((storeSearchParam) => {
          return {
            ...storeSearchParam,
            userSelect: searchParams.find((searchParam) => searchParam.key === storeSearchParam.key)
              .userSelect,
          };
        }),
      });
    }

    return defer(() => {
      return undefined$().pipe(
        switchMapTo(this.loadingStartBase(context)),
        switchMapTo(this.changeTitleBase(context, this.basicTitles.search)),
        switchMapTo(
          query({
            ...context.getState().searchParams.reduce((acc, searchParam) => {
              return {
                ...acc,
                [searchParam.key]: searchParam.userSelect,
              };
            }, {}),
            perPage: context.getState().tableParams.perPage,
            page:
              !!pageIndex || pageIndex === 0
                ? pageIndex + 1
                : context.getState().tableParams.currentPage,
          }),
        ),
        tap((response) => {
          context.patchState({
            tableDataParams: response.tableParams,
            units: response.units.data,
            tableParams: {
              ...response.units,
              ...{ data: undefined },
            },
          });
        }),
        finalize(() => {
          this.changeTitleBase(context, currentTitle);
          this.loadingEndBase(context);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  public abstract querySearchParams(context: StateContext<AnyUnitStateModel>): Observable<void>;

  public querySearchParamsBase(
    context: StateContext<CharsSearchUnitsStateModel>,
    currentTitle: string,
    query: (requestParameters: ReturnCharListRequestParams) => Observable<CharListResponseSDKModel>,
    defaults: CharsSearchUnitsStateModel,
  ): Observable<void>;
  public querySearchParamsBase(
    context: StateContext<UsersSearchUnitsStateModel>,
    currentTitle: string,
    query: (
      requestParameters: ReturnUserProfileListRequestParams,
    ) => Observable<UserProfileListResponseSDKModel>,
    defaults: UsersSearchUnitsStateModel,
  ): Observable<void>;
  public querySearchParamsBase(
    context: StateContext<CommsSearchUnitsStateModel>,
    currentTitle: string,
    query: (requestParameters: ReturnCommListRequestParams) => Observable<CommListResponseSDKModel>,
    defaults: CommsSearchUnitsStateModel,
  ): Observable<void>;
  public querySearchParamsBase(
    context: StateContext<GuildsSearchUnitsStateModel>,
    currentTitle: string,
    query: (
      requestParameters: ReturnGuildListRequestParams,
    ) => Observable<GuildListResponseSDKModel>,
    defaults: GuildsSearchUnitsStateModel,
  ): Observable<void>;
  public querySearchParamsBase(
    context: StateContext<AnyUnitStateModel>,
    currentTitle: string,
    query:
      | ((requestParameters: ReturnCharListRequestParams) => Observable<CharListResponseSDKModel>)
      | ((
          requestParameters: ReturnUserProfileListRequestParams,
        ) => Observable<UserProfileListResponseSDKModel>)
      | ((requestParameters: ReturnCommListRequestParams) => Observable<CommListResponseSDKModel>)
      | ((
          requestParameters: ReturnGuildListRequestParams,
        ) => Observable<GuildListResponseSDKModel>),
    defaults: AnyUnitStateModel,
  ): Observable<void> {
    return defer(() => {
      return undefined$().pipe(
        switchMapTo(this.loadingStartBase(context)),
        switchMapTo(this.changeTitleBase(context, this.titles.querySearchParams)),
        switchMapTo(
          query({
            page: defaults.tableParams.currentPage,
            perPage: context.getState().tableParams.perPage,
          }),
        ),
        tap((response) => {
          response.searchParams = response.searchParams.map((param: SearchParameterSDKModel) => {
            return {
              ...param,
              key: camelCase(param.key),
            };
          });

          context.patchState({
            tableDataParams: response.tableParams,
            units: response.units.data,
            tableParams: {
              ...response.units,
              ...{ data: undefined },
            },
            searchParams: response.searchParams,
          });
        }),
        finalize(() => {
          this.changeTitleBase(context, currentTitle);
          this.loadingEndBase(context);
        }),
        switchMapTo(undefined$()),
      );
    });
  }

  public addNewChildParams(
    context: StateContext<AnyUnitStateModel>,
    { newChildParams, currentSelectedParams }: SearchUnitsBase.AddNewChildParams,
  ): Observable<void> {
    // add user select to current state params
    // use only 'game' param at old values
    const currentState: AnyUnitStateModel = JSON.parse(JSON.stringify(context.getState()));
    const currentStateParams: UserSelectSearchParameterSDKModel[] = currentState.searchParams
      .filter((userSelectedParam) => userSelectedParam.key === 'game')
      .map((stateParam) => {
        const userSelectedParamValue = currentSelectedParams.find(
          (userSelectedParam) => userSelectedParam.id === stateParam.id,
        )?.userSelect;

        return {
          ...stateParam,
          userSelect: userSelectedParamValue,
        };
      });

    // add user select to new child params
    const newChildParamsWithSelect: UserSelectSearchParameterSDKModel[] = newChildParams.map(
      (childParam) => {
        const stateParamValue = currentStateParams.find(
          (stateParam) => stateParam.id === childParam.id,
        )?.userSelect;

        return {
          ...childParam,
          userSelect: stateParamValue,
        };
      },
    );

    context.patchState({
      searchParams: [...currentStateParams, ...newChildParamsWithSelect],
    });

    return undefined$();
  }
}
