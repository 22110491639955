import { Injectable } from '@angular/core';
import { AppSDKService } from '@clanhall-sdk/api/app.sdk.service';
import { AppSettingsSDKModel } from '@clanhall-sdk/model/appSettings.sdk.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { AppConfigActions } from '@store/system-states/app-config/app-config.actions';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppSettingSDKModel } from '@clanhall-sdk/model/appSetting.sdk.model';

export interface AppConfigStateModel {
  cmsSettings: AppSettingsSDKModel;
  oauth: AppSettingSDKModel;
}

@State<AppConfigStateModel>({
  name: 'appConfig',
  defaults: {
    cmsSettings: undefined,
    oauth: undefined,
  },
})
@Injectable()
export class AppConfigState {
  constructor(private appSDKService: AppSDKService) {}

  @Selector()
  public static getState(state: AppConfigStateModel) {
    return state;
  }

  @Selector()
  public static getCmsSettings(state: AppConfigStateModel): AppConfigStateModel['cmsSettings'] {
    return state.cmsSettings;
  }

  @Action(AppConfigActions.GetAppConfig)
  public getAppConfig(context: StateContext<AppConfigStateModel>): Observable<void> {
    return this.appSDKService.applicationSettings().pipe(
      switchMap((response: AppSettingsSDKModel) => {
        return context.dispatch(new AppConfigActions.SetAppConfig(response));
      }),
      map(() => undefined),
    );
  }

  @Action(AppConfigActions.SetAppConfig)
  public setAppConfig(
    context: StateContext<AppConfigStateModel>,
    { payload }: AppConfigActions.SetAppConfig,
  ): Observable<void> {
    context.patchState({
      cmsSettings: payload,
    });
    return undefined$();
  }
}
