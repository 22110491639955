import { SafeHtml } from '@angular/platform-browser';

export namespace Header {
  export class ChangeTitle {
    static readonly type: string = '[Header] Change title';

    constructor(public payload: string) {}
  }

  export class TurnOnLoading {
    static readonly type: string = '[Header] Loading start';
  }

  export class TurnOffLoading {
    static readonly type: string = '[Header] Loading end';
  }

  export class ChangeLeftText {
    static readonly type: string = '[Header] Change left text';

    constructor(public leftText: string | SafeHtml) {}
  }

  export class ChangeRightText {
    static readonly type: string = '[Header] Change right text';

    constructor(public rightText: string | SafeHtml | undefined) {}
  }
}
