<app-page-header *ngIf="(isMobile$ | async) === false"></app-page-header>
<mat-drawer-container
  (backdropClick)="sidebarService.closeSidebars()"
  [hasBackdrop]="hasBackdrop$ | async"
  autosize
>
  <mat-drawer
    #sidebarLeft
    [disableClose]="isMiddle$ | async"
    [mode]="(leftSidebar$ | async).mode"
    [ngStyle]="leftSidebarStyle$ | async"
    position="start"
  >
    <app-left-menu></app-left-menu>
  </mat-drawer>
  <mat-drawer
    #sidebarRight
    [disableClose]="isMiddle$ | async"
    [mode]="(rightSidebar$ | async).mode"
    [ngStyle]="rightSidebarStyle$ | async"
    position="end"
  >
    <app-right-menu></app-right-menu>
  </mat-drawer>
  <mat-drawer-content
    [ngStyle]="wrapperStyle$ | async"
    [ngClass]="{ mobile: isMobile$ | async }"
    class="wrapper"
  >
    <app-page-header *ngIf="isMobile$ | async"></app-page-header>
    <div [ngStyle]="headerStyle$ | async" class="main">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
