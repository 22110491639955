import { STATE_TYPE } from '@store/common-states/search/shared/base/action-base-types';
import { SearchUnitsBase } from '@store/common-states/search/shared/base/shared-search-units.actions';

export namespace SearchCharsUnitsActions {
  const ACTIONS_NAME = '[SearchCharsUnits] ';

  export class LoadingStart extends SearchUnitsBase.LoadingStart {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.start;
  }

  export class LoadingEnd extends SearchUnitsBase.LoadingEnd {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.end;
  }

  export class ChangeTitle extends SearchUnitsBase.ChangeTitle {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.title.change;
  }

  export class UpdatePage extends SearchUnitsBase.UpdatePage {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.updatePage;
  }

  export class QuerySearchParams extends SearchUnitsBase.QuerySearchParams {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.searchParams;
  }

  export class ResetSearch extends SearchUnitsBase.ResetSearch {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.resetSearch;
  }

  export class Search extends SearchUnitsBase.Search {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.search;
  }

  export class AddNewChildParams extends SearchUnitsBase.AddNewChildParams {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.addNewChildParams;
  }
}
