export const STATE_TYPE = {
  loading: {
    start: 'Loading start',
    end: 'Loading end',
  },
  title: {
    change: 'Change page title',
  },
  get: {
    searchParams: 'Query my units list',
    newPage: 'Query new page (next page).',
  },
  updatePage: 'Update page by paginator.',
  search: 'Search.',
  resetSearch: 'Reset serach.',
  addNewChildParams: 'Add new child params.',
};
