import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WindowState } from '@store/ux-states/window/window.state';
import { Observable } from 'rxjs';

@Injectable()
export class WindowStoreService {
  @Select(WindowState.isMobile)
  public readonly isMobile$: Observable<boolean>;

  @Select(WindowState.isMiddle)
  public readonly isMiddle$: Observable<boolean>;

  @Select(WindowState.isLarge)
  public isLarge$: Observable<boolean>;

  constructor(protected store: Store) {}

  public get isMobile(): boolean {
    return this.store.selectSnapshot(WindowState.isMobile);
  }

  public get isMiddle(): boolean {
    return this.store.selectSnapshot(WindowState.isMiddle);
  }

  public get isLarge(): boolean {
    return this.store.selectSnapshot(WindowState.isLarge);
  }
}
