import { UnitParameterSDKModelLinkTypeEnum } from '@clanhall-sdk/model/unitParameter.sdk.model';
import { EnumHelperStaticService } from '@shared/services/static/enum-helper-static.service';

/**
 * при изменении обратить внимание на косвенную связь
 * с linkType (UnitParameterSDKModelLinkTypeEnum)
 */
export enum UnitRouteEnum {
  char = 'char',
  user = 'user',
  guild = 'guild',
  comm = 'comm',
}

export namespace UnitRouteEnum {
  export function getFullLink(route: UnitRouteEnum): string {
    return `/${route}`;
  }

  export function unitById(
    route: UnitRouteEnum | UnitParameterSDKModelLinkTypeEnum,
    id: number,
  ): string {
    if (
      EnumHelperStaticService.getAllEnumKeys<UnitRouteEnum>(UnitRouteEnum).some(
        (key) => route === key,
      )
    ) {
      return `${getFullLink(route as UnitRouteEnum)}/${id}`;
    }
    throw Error(`DEV_ERROR: Непрогнозируемое поведение. Ключ "${route}" не соответствует юнитам.`);
  }
}

export enum OtherRouteEnum {
  welcome = 'welcome',
}
