import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidthContainerComponent } from '@components/width-container/width-container.component';

@NgModule({
  declarations: [WidthContainerComponent],
  exports: [WidthContainerComponent],
  imports: [CommonModule],
})
export class WidthContainerModule {}
