import { animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SidebarService } from '@shared/services/sidebar.service';
import { fadeIn, fadeOut } from 'ng-animate';
import { combineLatest, Observable } from 'rxjs';
import { MyProfileRoutesService } from '@shared/services/my-profile-routes.service';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@store/system-states/auth/auth.state';
import { map } from 'rxjs/operators';
import { PageHeaderStoreService } from './page-header.store.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PageHeaderStoreService],
  animations: [
    trigger('title', [
      transition('* => *', [
        useAnimation(fadeOut, { params: { timing: '0.1' } }),
        useAnimation(fadeIn, { params: { timing: '0.2' } }),
      ]),
    ]),
    trigger('loading', [
      transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PageHeaderComponent {
  public readonly title$: Observable<string> = this.pageHeaderStoreService.titleInView$;

  public readonly loading$: Observable<boolean> = this.pageHeaderStoreService.loading$;

  public readonly leftText$: Observable<string> = this.pageHeaderStoreService.leftText$;

  public readonly rightText$: Observable<string> = this.pageHeaderStoreService.rightText$;

  public readonly isMobile$: Observable<boolean> = this.pageHeaderStoreService.isMobile$;

  public readonly isMiddle$: Observable<boolean> = this.pageHeaderStoreService.isMiddle$;

  public readonly defaultLeftText: string = `<a href="./">ClanHall</a>`;

  @Select(AuthState.isLoggedIn)
  public isLoggedIn$: Observable<boolean>;

  @Select(AuthState.userName)
  public userName$: Observable<string>;

  public showEmptyUserDataAnnouncement$: Observable<boolean> = combineLatest([
    this.store.select(AuthState.isLoggedIn),
    this.store.select(AuthState.userName),
  ]).pipe(
    map(([loggedIn, userName]) => {
      return loggedIn && (!userName || userName.toLowerCase() === 'безымянный');
    }),
  );

  constructor(
    private sidebarService: SidebarService,
    private pageHeaderStoreService: PageHeaderStoreService,
    public myProfileRoutesService: MyProfileRoutesService,
    private store: Store,
  ) {}

  public toggleLeftMobileSidebar(): void {
    this.sidebarService.toggleLeft();
  }

  public toggleRightMobileSidebar(): void {
    this.sidebarService.toggleRight();
  }

  public toggleRightMiddleSidebar(): void {
    this.sidebarService.toggleRightMiddleSidebar();
  }

  public toggleLeftMiddleSidebar(): void {
    this.sidebarService.toggleLeftMiddleSidebar();
  }
}
