import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WindowStoreService } from '@shared/services/window.store.service';
import { HeaderState } from '@store/ux-states/header/header.state';
import { Observable } from 'rxjs';

@Injectable()
export class PageHeaderStoreService extends WindowStoreService {
  @Select(HeaderState.loading)
  public readonly loading$: Observable<boolean>;

  @Select(HeaderState.leftText)
  public readonly leftText$: Observable<string>;

  @Select(HeaderState.rightText)
  public readonly rightText$: Observable<string>;

  @Select(HeaderState.title)
  public title$: Observable<string>;

  public titleInView$: Observable<string> = this.store.select(HeaderState.title);

  constructor(protected store: Store) {
    super(store);
  }
}
