import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import {
  ROUTE_REUSE_STORE_KEY,
  ROUTE_SHOULD_REUSE,
} from '@shared/constants/reuse-stratagy.constants';

export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.data[ROUTE_SHOULD_REUSE]) {
      return null;
    }
    return this.handlers[route.data[ROUTE_REUSE_STORE_KEY]];
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.data[ROUTE_SHOULD_REUSE]) {
      this.handlers[route.data[ROUTE_REUSE_STORE_KEY]] = handle;
    }
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data[ROUTE_SHOULD_REUSE];
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data[ROUTE_SHOULD_REUSE] && !!this.handlers[route.data[ROUTE_REUSE_STORE_KEY]];
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    // current: ActivatedRouteSnapshot,
  ): boolean {
    return !!future.data[ROUTE_SHOULD_REUSE];
  }
}
