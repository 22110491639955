import { STATE_TYPE } from '@store/common-states/units/shared/base/base-units.state.directive';
import {
  ChangeTitleBase,
  LoadingEndBase,
  LoadingStartBase,
  QueryUnitByIdBase,
  SaveUnitAvatarBase,
  SaveUnitParamsBase,
} from '@store/common-states/units/shared/base/shared-units.actions';

const ACTIONS_NAME = '[UserUnit] ';

export namespace UserUnitActions {
  export class LoadingStart extends LoadingStartBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.start;
  }

  export class LoadingEnd extends LoadingEndBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.end;
  }

  export class ChangeTitle extends ChangeTitleBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.title;
  }

  export class QueryUnitById extends QueryUnitByIdBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.unitById;
  }

  export class SaveUnitParams extends SaveUnitParamsBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitParams;
  }

  export class SaveUnitAvatar extends SaveUnitAvatarBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitAvatar;
  }

  export class AddMyUserId {
    static readonly type: string = `${ACTIONS_NAME}Add my user id.`;

    constructor(public userId: number) {}
  }
}
