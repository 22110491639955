import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnitChildParamsDirectApiService } from '@modules/units/shared/services/unit-child-params-direct-api.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'; // only dev
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  DEVTOOLS_REDUX_CONFIG,
  LOGGER_CONFIG,
  OPTIONS_CONFIG,
  STATES_MODULES,
  STORAGE_CONFIG,
} from './store.config';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(STATES_MODULES, OPTIONS_CONFIG),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(STORAGE_CONFIG),
  ],
  providers: [UnitChildParamsDirectApiService],
  exports: [NgxsModule],
})
export class NgxsStoreModule {}
