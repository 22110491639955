import { SidebarMode } from '@store/ux-states/sidebars/sidebars.state';

export namespace Sidebars {
  export class CloseSidebars {
    static readonly type: string = '[Sidebars] Close all sidebars';
  }

  export class ChangeLeftSidebarMode {
    static readonly type: string = '[Sidebars] Change left sidebar mode';

    constructor(public mode: SidebarMode) {}
  }

  export class ChangeRightSidebarMode {
    static readonly type: string = '[Sidebars] Change right sidebar mode';

    constructor(public mode: SidebarMode) {}
  }

  export class ChangeLeftSidebarViewState {
    static readonly type: string = "[Sidebars] Change 'isFull' left sidebar status";

    constructor(public isFull: boolean) {}
  }

  export class ChangeRightSidebarViewState {
    static readonly type: string = "[Sidebars] Change 'isFull' right sidebar status";

    constructor(public isFull: boolean) {}
  }

  export class ChangeBackdropStatus {
    static readonly type: string = '[Sidebars] Change backdrop status';

    constructor(public status: boolean) {}
  }
}
