import { Injectable } from '@angular/core';
import { Sidebars } from '@store/ux-states/sidebars/sidebars.actions';
import { WindowState } from '@store/ux-states/window/window.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

export type SidebarMode = 'push' | 'over' | 'side';

export interface SidebarProp {
  mode: SidebarMode;
  isFull: boolean;
}

export interface SidebarsStateModel {
  hasBackdrop: boolean;
  left: SidebarProp;
  right: SidebarProp;
}

const defaults = {
  hasBackdrop: false,
  left: {
    mode: undefined,
    isFull: undefined,
  },
  right: {
    mode: undefined,
    isFull: undefined,
  },
};

@Injectable()
@State<SidebarsStateModel>({
  name: 'sidebars',
  defaults,
})
export class SidebarsState {
  constructor(private store: Store) {}

  @Selector()
  public static leftSidebar(state: SidebarsStateModel): SidebarsStateModel['left'] {
    return state.left;
  }

  @Selector()
  public static rightSidebar(state: SidebarsStateModel): SidebarsStateModel['right'] {
    return state.right;
  }

  @Selector()
  public static hasBackdrop(state: SidebarsStateModel): SidebarsStateModel['hasBackdrop'] {
    return state.hasBackdrop;
  }

  @Action(Sidebars.CloseSidebars)
  public closeSidebars(context: StateContext<SidebarsStateModel>): void {
    if (context.getState().right.isFull && !this.store.selectSnapshot(WindowState.isMobile)) {
      context.patchState({
        right: {
          ...context.getState().right,
          isFull: false,
        },
      });
    }

    if (context.getState().left.isFull && !this.store.selectSnapshot(WindowState.isMobile)) {
      context.patchState({
        left: {
          ...context.getState().left,
          isFull: false,
        },
      });
    }
  }

  @Action(Sidebars.ChangeLeftSidebarMode)
  public changeLeftSidebarMode(
    context: StateContext<SidebarsStateModel>,
    { mode }: Sidebars.ChangeLeftSidebarMode,
  ): void {
    if (context.getState().left.mode === mode) return;

    context.patchState({
      left: {
        ...context.getState().left,
        mode,
      },
    });
  }

  @Action(Sidebars.ChangeRightSidebarMode)
  public changeRightSidebarMode(
    context: StateContext<SidebarsStateModel>,
    { mode }: Sidebars.ChangeRightSidebarMode,
  ): void {
    if (context.getState().right.mode === mode) return;

    context.patchState({
      right: {
        ...context.getState().right,
        mode,
      },
    });
  }

  @Action(Sidebars.ChangeLeftSidebarViewState)
  public changeLeftSidebarViewState(
    context: StateContext<SidebarsStateModel>,
    { isFull }: Sidebars.ChangeLeftSidebarViewState,
  ): void {
    if (context.getState().left.isFull === isFull) return;

    context.patchState({
      left: {
        ...context.getState().left,
        isFull,
      },
    });
  }

  @Action(Sidebars.ChangeRightSidebarViewState)
  public changeRightSidebarViewState(
    context: StateContext<SidebarsStateModel>,
    { isFull }: Sidebars.ChangeRightSidebarViewState,
  ): void {
    if (context.getState().right.isFull === isFull) return;

    context.patchState({
      right: {
        ...context.getState().right,
        isFull,
      },
    });
  }

  @Action(Sidebars.ChangeBackdropStatus)
  public changeBackdropStatus(
    context: StateContext<SidebarsStateModel>,
    { status }: Sidebars.ChangeBackdropStatus,
  ): void {
    if (context.getState().hasBackdrop === status) return;

    context.patchState({ hasBackdrop: status });
  }
}
