export const MY_ROUTE: string = 'my';

export enum MyProfileRoutesEnum {
  // user has generic link
  chars = 'chars',
  settings = 'settings',
  comms = 'comms',
  guilds = 'guilds',
}

export namespace MyProfileRoutesEnum {
  export function getFullLink(route: MyProfileRoutesEnum): string {
    return `/${MY_ROUTE}/${route}`;
  }
}
