import { Injectable } from '@angular/core';
import { MyUnitSDKService } from '@clanhall-sdk/api/myUnit.sdk.service';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { CharsUnitsActions } from '@store/common-states/units/chars/chars-units.actions';
import { BaseUnitsState } from '@store/common-states/units/shared/base/base-units.state';
import {
  BaseUnitStateModelWithLists,
  getBaseUnitModel,
  getBaseUnitsLists,
} from '@store/common-states/units/shared/base/base-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import { CharSDKService } from '@clanhall-sdk/api/char.sdk.service';
import { MyCharSDKService } from '@clanhall-sdk/api/myChar.sdk.service';
import { CharInListSDKModel } from '@clanhall-sdk/model/charInList.sdk.model';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { Observable } from 'rxjs';

export interface CharsUnitsStateModel
  extends BaseUnitStateModelWithLists<CharInListSDKModel, TableParameterSDKModel> {}

const defaults: CharsUnitsStateModel = {
  ...getBaseUnitModel(),
  ...getBaseUnitsLists(),
};

@State<CharsUnitsStateModel>({
  name: 'chars',
  defaults,
})
@Injectable()
export class CharsUnitsState extends BaseUnitsState {
  private titles = {
    getMyUnits: 'Запрашиваем твоих персонажей',
    getMyArchivedUnits: 'Запрашиваем твоих архивных персонажей',
    loadUnit: 'Загружаем персонажа',
    saveUnit: 'Сохраняем персонажа',
    addUnit: 'Создаем персонажа',
    deleteUnit: 'Удаляем персонажа',
    restoreUnit: 'Восстанавливаем персонажа',
  };

  constructor(
    private store: Store,
    private myCharSDKService: MyCharSDKService,
    private charSDKService: CharSDKService,
    protected myUnitSDKService: MyUnitSDKService,
  ) {
    super(myUnitSDKService);
  }

  @Selector()
  static loading(state: CharsUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: CharsUnitsStateModel) {
    return state.loadedUnits;
  }

  static unit(id: number) {
    return createSelector([CharsUnitsState], (state: CharsUnitsStateModel) => {
      return state.loadedUnits[id];
    });
  }

  @Selector()
  static myUnitsList(state: CharsUnitsStateModel) {
    return state.unitsList;
  }

  @Selector()
  static myArchivedUnitsList(state: CharsUnitsStateModel) {
    return state.archivedUnitsList;
  }

  @Selector()
  static myUnitsIds(state: CharsUnitsStateModel) {
    return this.filterOnlyMyIds(state.loadedUnits);
  }

  @Selector()
  static listParams(state: CharsUnitsStateModel) {
    return state.unitsListParams;
  }

  @Selector()
  static myArchivedUnitsListParams(state: CharsUnitsStateModel) {
    return state.archivedUnitsListParams;
  }

  @Action(CharsUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<CharsUnitsStateModel>): Observable<void> {
    return this.loadingStartBase(context as any);
  }

  @Action(CharsUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<CharsUnitsStateModel>): Observable<void> {
    return this.loadingEndBase(context as any);
  }

  @Action(CharsUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<CharsUnitsStateModel>,
    { title }: CharsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return this.changeTitleBase(context as any, title);
  }

  @Action(CharsUnitsActions.SaveUnitParams)
  public saveUnitParams(
    context: StateContext<CharsUnitsStateModel>,
    payload: CharsUnitsActions.SaveUnitParams,
  ): Observable<void> {
    return this.saveUnitParamsBase(
      context as any,
      payload,
      'char',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(CharsUnitsActions.SaveUnitAvatar)
  public saveUnitAvatar(
    context: StateContext<CharsUnitsStateModel>,
    payload: CharsUnitsActions.SaveUnitAvatar,
  ): Observable<void> {
    return this.saveUnitAvatarBase(
      context as any,
      payload,
      'char',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(CharsUnitsActions.DeleteUnit)
  public deleteUnit(
    context: StateContext<CharsUnitsStateModel>,
    payload: CharsUnitsActions.DeleteUnit,
  ): Observable<void> {
    return this.deleteUnitBase(
      context,
      payload,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.deleteUnit,
      () => this.myCharSDKService.deleteMyCharProfile({ id: payload.unitId }),
    );
  }

  @Action(CharsUnitsActions.RestoreUnit)
  public restoreUnit(
    context: StateContext<CharsUnitsStateModel>,
    payload: CharsUnitsActions.RestoreUnit,
  ): Observable<void> {
    return this.restoreUnitBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.restoreUnit,
      () => this.myCharSDKService.restoreMyCharProfile({ id: payload.unitId }),
    );
  }

  @Action(CharsUnitsActions.QueryUnitsList)
  public queryUnitsList(context: StateContext<CharsUnitsStateModel>): Observable<any> {
    return this.queryMyUnitsListBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyUnits,
      () => this.myCharSDKService.returnMyCharList(),
    );
  }

  @Action(CharsUnitsActions.QueryArchivedUnitsList)
  public queryArchivedUnitsList(context: StateContext<CharsUnitsStateModel>): Observable<any> {
    return this.queryMyArchivedUnitsListBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.getMyArchivedUnits,
      () => this.myCharSDKService.returnMyArchiveCharList(),
    );
  }

  @Action(CharsUnitsActions.QueryUnitById)
  public queryUnitById(
    context: StateContext<CharsUnitsStateModel>,
    payload: CharsUnitsActions.QueryUnitById,
  ): Observable<any> {
    return this.queryUnitByIdBase(
      context as any,
      payload.unitId,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.loadUnit,
      () => this.charSDKService.returnCharProfile({ id: payload.unitId }),
    );
  }

  @Action(CharsUnitsActions.AddMyUnit)
  public addMyUnit(
    context: StateContext<CharsUnitsStateModel>,
    { params }: CharsUnitsActions.AddMyUnit,
  ): Observable<any> {
    return this.addMyUnitBase(
      context,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.addUnit,
      () => this.myCharSDKService.addChar(params),
    );
  }
}
