import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WindowStoreService } from '@shared/services/window.store.service';
import { AuthState } from '@store/system-states/auth/auth.state';
import { Header } from '@store/ux-states/header/header.actions';
import { SidebarsState, SidebarProp } from '@store/ux-states/sidebars/sidebars.state';
import { Observable } from 'rxjs';

@Injectable()
export class RightSidebarStoreService extends WindowStoreService {
  @Select(AuthState.isLoggedIn)
  public isLoggedIn$: Observable<boolean>;

  @Select(SidebarsState.rightSidebar)
  public rightSidebar$: Observable<SidebarProp>;

  @Select(AuthState.userName)
  public userName$: Observable<string>;

  constructor(protected store: Store) {
    super(store);
  }

  public changeHeaderRightText(value: Header.ChangeRightText['rightText']): void {
    this.store.dispatch(new Header.ChangeRightText(value));
  }
}
