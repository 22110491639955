import { UnitActionSDKModel, UnitParameterSDKModel } from '@clanhall-sdk/model/models';

export interface BasicUnitInterface {
  isMy: boolean;
  params: UnitParameterSDKModel[];
  actions: UnitActionSDKModel[];
}

export interface BaseUnitStateModel {
  loading: boolean;
  loadedUnits: {
    [key: number]: BasicUnitInterface;
  };
}

export interface BaseUnitStateModelWithLists<UnitInListModel = any, UnitListParams = any>
  extends BaseUnitStateModel {
  unitsList: UnitInListModel[];
  unitsListParams: UnitListParams[];
  archivedUnitsList: UnitInListModel[];
  archivedUnitsListParams: UnitListParams[];
}

export function getBaseUnitModel(): BaseUnitStateModel {
  return JSON.parse(
    JSON.stringify({
      loading: false,
      loadedUnits: {},
    }),
  );
}

export function getBaseUnitsLists(): BaseUnitStateModelWithLists {
  return JSON.parse(
    JSON.stringify({
      unitsList: [],
      unitsListParams: [],
      archivedUnitsList: [],
      archivedUnitsListParams: [],
    }),
  );
}

export const STATE_TYPE = {
  loading: {
    start: 'Loading start',
    end: 'Loading end',
  },
  title: {
    change: 'Change page title',
  },
  get: {
    unitById: 'Query unit by id',
    myUnitsList: 'Query my units list',
    myArchivedUnitsList: 'Query my archived units list',
  },
  post: {
    myUnitParams: 'Save unit params',
    myUnitAvatar: 'Save unit avatar',
    myUnitAbout: 'Save my unit about',
    addMyUnit: 'Add new my unit',
    deleteUnit: 'Delete unit',
    restoreUnit: 'Restore unit',
  },
};
