import {
  SaveMyUnitAvatarRequestParams,
  SaveMyUnitParamsRequestParams,
} from '@clanhall-sdk/api/myUnit.sdk.service';

const ACTION_TYPE_DUMB: string = 'Set action name.';

export class LoadingStartBase {
  static readonly type: string = ACTION_TYPE_DUMB;
}

export class LoadingEndBase {
  static readonly type: string = ACTION_TYPE_DUMB;
}

export class ChangeTitleBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public title: string) {}
}

export class QueryUnitByIdBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public unitId: number) {}
}

export class SaveUnitParamsBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(
    public unitId: SaveMyUnitParamsRequestParams['unitId'],
    public params: SaveMyUnitParamsRequestParams['saveUnitParamsSDKModel'],
  ) {}
}

export class SaveUnitAvatarBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(
    public unitId: SaveMyUnitParamsRequestParams['unitId'],
    public avatarBlob: SaveMyUnitAvatarRequestParams['avatar'],
  ) {}
}

export class QueryUnitsListBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public unitId?: number) {}
}

export class QueryMyArchivedUnitsListBase {
  static readonly type: string = ACTION_TYPE_DUMB;
}

export class DeleteUnitBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public unitId: number) {}
}

export class RestoreUnitBase {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public unitId: number) {}
}

export class AddMyUnitBase<AddRequestParams> {
  static readonly type: string = ACTION_TYPE_DUMB;

  constructor(public params: AddRequestParams) {}
}
