import { Injectable } from '@angular/core';
import { WindowActions } from '@store/ux-states/window/window.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface WindowStateModel {
  innerWidth: number;
  mobileWidth: number;
  largeWidth: number;
  isMobile: boolean;
  isMiddle: boolean;
  isLarge: boolean;
}

@Injectable()
@State<WindowStateModel>({
  name: 'window',
  defaults: WindowState.defaults,
})
export class WindowState {
  static get defaults(): WindowStateModel {
    return {
      innerWidth: undefined,
      mobileWidth: 1024,
      largeWidth: 1572,
      isMobile: false,
      isMiddle: false,
      isLarge: false,
    };
  }

  @Selector()
  public static getState(state: WindowStateModel): WindowStateModel {
    return state;
  }

  @Selector()
  public static isMobile(state: WindowStateModel): WindowStateModel['isMobile'] {
    return state.isMobile;
  }

  @Selector()
  public static isMiddle(state: WindowStateModel): WindowStateModel['isMiddle'] {
    return state.isMiddle;
  }

  @Selector()
  public static isLarge(state: WindowStateModel): WindowStateModel['isLarge'] {
    return state.isLarge;
  }

  @Selector()
  public static innerWidth(state: WindowStateModel): WindowStateModel['innerWidth'] {
    return state.innerWidth;
  }

  @Action(WindowActions.SetScreenWidth)
  public setScreenWidth(
    { getState, patchState }: StateContext<WindowStateModel>,
    { width }: WindowActions.SetScreenWidth,
  ): void {
    if (!width) return;

    // eslint-disable-next-line prefer-const
    let { mobileWidth, largeWidth, isMobile, isMiddle, isLarge } = { ...getState() };

    if (width < mobileWidth && !isMobile) {
      isMobile = true;
    } else if (!(width < mobileWidth) && isMobile) {
      isMobile = false;
    }

    if (mobileWidth <= width && width < largeWidth && !isMiddle) {
      isMiddle = true;
    } else if (!(mobileWidth <= width && width < largeWidth) && isMiddle) {
      isMiddle = false;
    }

    if (largeWidth <= width && !isLarge) {
      isLarge = true;
    } else if (!(largeWidth <= width) && isLarge) {
      isLarge = false;
    }

    patchState({
      isMobile,
      isMiddle,
      isLarge,
      innerWidth: width,
    });
  }
}
