import { LoginRequestParams, RegisterRequestParams } from '@clanhall-sdk/api/auth.sdk.service';
import { AuthStateModel } from '@store/system-states/auth/auth.state';

export namespace AuthActions {
  const ACTIONS_NAME = '[Auth] ';

  export class LoginWithEmail {
    static readonly type: string = `${ACTIONS_NAME}Authorisation with email and password.`;

    constructor(public payload: LoginRequestParams) {}
  }

  export class Logout {
    static readonly type: string = `${ACTIONS_NAME}Logout user.`;
  }

  export class RegisterWithEmail {
    static readonly type: string = `${ACTIONS_NAME}Register with email.`;

    constructor(public payload: RegisterRequestParams) {}
  }

  export class InitUser {
    static readonly type: string = `${ACTIONS_NAME}Get current user data.`;
  }

  export class SendAuthLinkToEmail {
    static readonly type: string = `${ACTIONS_NAME}Send auth link to email.`;

    constructor(public email: string) {}
  }

  export class LoginWithToken {
    static readonly type: string = `${ACTIONS_NAME}Login with email and token.`;

    constructor(public userId: number, public token: string) {}
  }

  export class ClearAuthState {
    static readonly type: string = `${ACTIONS_NAME}Clear auth state.`;
  }

  export class SetDiscordAttachState {
    public static readonly type: string = '[AppConfig] Set discord attach state.';

    constructor(public payload: AuthStateModel['discordIsAttached']) {}
  }
}
