<mat-nav-list>
  <mat-list-item
    (click)="closeSidebar()"
    *ngIf="isLoggedIn$ | async"
    [routerLink]="settingsRoutesEnum.getFullLink(settingsRoutesEnum.app)"
  >
    <mat-icon matListIcon>settings</mat-icon>
    <a
      *ngIf="(rightSidebar$ | async).isFull"
      [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SETTINGS' | translate"
      matLine
    ></a>
  </mat-list-item>
  <mat-list-item
    (click)="closeSidebar()"
    *ngIf="isLoggedIn$ | async"
    [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.logout)"
  >
    <mat-icon matListIcon>logout</mat-icon>
    <a
      *ngIf="(rightSidebar$ | async).isFull"
      [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.LOGOUT' | translate"
      matLine
    ></a>
  </mat-list-item>
  <mat-list-item
    (click)="closeSidebar()"
    *ngIf="(isLoggedIn$ | async) === false"
    [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.login)"
  >
    <mat-icon matListIcon>login</mat-icon>
    <a
      *ngIf="(rightSidebar$ | async).isFull"
      [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.LOGIN' | translate"
      matLine
    ></a>
  </mat-list-item>
  <!--  <mat-list-item-->
  <!--      (click)="closeSidebar()"-->
  <!--      *ngIf="!(isLoggedIn$ | async)"-->
  <!--      [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.registration)"-->
  <!--  >-->
  <!--    <mat-icon matListIcon>person_add</mat-icon>-->
  <!--    <a-->
  <!--        *ngIf="(rightSidebar$ | async).isFull"-->
  <!--        [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SIGNIN' | translate"-->
  <!--        matLine-->
  <!--    ></a>-->
  <!--  </mat-list-item>-->
  <mat-divider></mat-divider>
</mat-nav-list>
<div style="flex: 1"></div>
<mat-nav-list>
  <mat-divider></mat-divider>
  <a
    href="https://discord.gg/ec3XW2eSQz"
    mat-list-item
    target="_blank"
    [matTooltip]="'Перейти в наш Discord'"
  >
    <mat-icon matListIcon svgIcon="logos:discord"></mat-icon>
    <div mat-line [innerHTML]="'Наш Discord'"></div>
  </a>
  <a
    href="https://vk.com/clan_hall"
    mat-list-item
    target="_blank"
    [matTooltip]="'Перейти в группу VK'"
  >
    <mat-icon matListIcon svgIcon="logos:vk"></mat-icon>
    <div mat-line [innerHTML]="'Группа в VK'"></div>
  </a>
  <mat-divider></mat-divider>
  <a
    href="https://discord.gg/5g9ByQUV7x"
    mat-list-item
    target="_blank"
    [matTooltip]="'Написать в наш Discord-канал'"
  >
    <mat-icon matListIcon>bug_report</mat-icon>
    <div mat-line [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SEND_BUGS' | translate"></div>
  </a>
</mat-nav-list>
<mat-nav-list>
  <mat-divider></mat-divider>
  <mat-list-item (click)="closeSidebar()" routerLink="/docs">
    <mat-icon matListIcon>info</mat-icon>
    <a
      *ngIf="(rightSidebar$ | async).isFull"
      [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.INFO' | translate"
      matLine
    ></a>
  </mat-list-item>
</mat-nav-list>
