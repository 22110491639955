import { Injectable } from '@angular/core';
import { UnitChildParamsDirectApiService } from '@modules/units/shared/services/unit-child-params-direct-api.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SearchGuildsUnitsActions } from '@store/common-states/search/guilds/search-guilds-units.actions';
import {
  BaseSearchUnitsState,
  BasicSearchUnitStateModel,
  getBasicSearchUnitStateModelDefaults,
  UserSelectSearchParameterSDKModel,
} from '@store/common-states/search/shared/base/base-search-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import {
  GuildSDKService,
  ReturnGuildListRequestParams,
  SearchGuildsRequestParams,
} from '@clanhall-sdk/api/guild.sdk.service';
import { GuildInListSDKModel } from '@clanhall-sdk/model/guildInList.sdk.model';
import { GuildListSDKModel } from '@clanhall-sdk/model/guildList.sdk.model';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface GuildsSearchUnitsStateModel extends BasicSearchUnitStateModel {
  units: GuildInListSDKModel[];
  tableParams: Omit<GuildListSDKModel, 'data'>;
}

const defaults: GuildsSearchUnitsStateModel = {
  ...getBasicSearchUnitStateModelDefaults(),
  units: [],
};

@State<GuildsSearchUnitsStateModel>({
  name: 'searchGuilds',
  defaults,
})
@Injectable()
export class SearchGuildsUnitsState extends BaseSearchUnitsState {
  protected titles = {
    ...this.basicTitles,
  };

  constructor(
    protected store: Store,
    private guildSDKService: GuildSDKService,
    private directUnitApiService: UnitChildParamsDirectApiService,
  ) {
    super();
  }

  @Selector()
  static loading(state: GuildsSearchUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: GuildsSearchUnitsStateModel): GuildInListSDKModel[] {
    return state.units;
  }

  @Selector()
  static tableParams(state: GuildsSearchUnitsStateModel): Omit<GuildListSDKModel, 'data'> {
    return state.tableParams;
  }

  @Selector()
  static tableDataParams(state: GuildsSearchUnitsStateModel): TableParameterSDKModel[] {
    return state.tableDataParams;
  }

  @Selector()
  static searchParams(state: GuildsSearchUnitsStateModel): UserSelectSearchParameterSDKModel[] {
    return state.searchParams;
  }

  @Action(SearchGuildsUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<GuildsSearchUnitsStateModel>,
    { title }: SearchGuildsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return super.changeTitleBase(context, title);
  }

  @Action(SearchGuildsUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<GuildsSearchUnitsStateModel>): Observable<void> {
    return super.loadingEndBase(context);
  }

  @Action(SearchGuildsUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<GuildsSearchUnitsStateModel>): Observable<void> {
    return super.loadingStartBase(context);
  }

  @Action(SearchGuildsUnitsActions.UpdatePage)
  public updatePage(
    context: StateContext<GuildsSearchUnitsStateModel>,
    payload: SearchGuildsUnitsActions.UpdatePage,
  ): Observable<void> {
    return super.updatePageBase(context, payload, new SearchGuildsUnitsActions.Search());
  }

  @Action(SearchGuildsUnitsActions.QuerySearchParams)
  public querySearchParams(context: StateContext<GuildsSearchUnitsStateModel>): Observable<void> {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);

    return super.querySearchParamsBase(
      context,
      currentTitle,
      (requestParameters: ReturnGuildListRequestParams) =>
        this.guildSDKService.returnGuildList(requestParameters),
      defaults,
    );
  }

  @Action(SearchGuildsUnitsActions.ResetSearch)
  public resetSearch({
    patchState,
    getState,
    dispatch,
  }: StateContext<GuildsSearchUnitsStateModel>) {
    const gameSearchParam: UserSelectSearchParameterSDKModel = getState().searchParams.find(
      (param: UserSelectSearchParameterSDKModel) => param.key === 'game',
    );

    return this.directUnitApiService
      .getGameChildParamsInSeach({
        gameId: gameSearchParam.userSelect as number,
        unitType: 'guild',
      })
      .pipe(
        switchMap((responseParams) => {
          patchState({
            searchParams: [
              ...responseParams.map((param) => {
                return {
                  ...param,
                  userSelect: null,
                };
              }),
              gameSearchParam,
            ],
          });
          return dispatch(new SearchGuildsUnitsActions.Search());
        }),
      );
  }

  @Action(SearchGuildsUnitsActions.Search)
  public search(
    context: StateContext<GuildsSearchUnitsStateModel>,
    payload: SearchGuildsUnitsActions.Search,
  ) {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);
    return super.searchBase(
      context,
      payload,
      (requestParameters: SearchGuildsRequestParams) =>
        this.guildSDKService.searchGuilds(requestParameters),
      currentTitle,
    );
  }

  @Action(SearchGuildsUnitsActions.AddNewChildParams)
  public addNewChildParams(
    context: StateContext<GuildsSearchUnitsStateModel>,
    payload: SearchGuildsUnitsActions.AddNewChildParams,
  ): Observable<void> {
    return super.addNewChildParams(context, payload);
  }
}
