import { Injectable } from '@angular/core';
import {
  ReturnChildParamsWithOptionsRequestParams,
  ReturnSearchChildParamsWithOptionsRequestParams,
  ReturnUnitParamsWithOptionsRequestParams,
  UnitSDKService,
} from '@clanhall-sdk/api/unit.sdk.service';
import {
  ReturnChildParamsWithOptionsResponseSDKModel,
  ReturnSearchChildParamsWithOptionsResponseSDKModel,
  SearchParameterSDKModel,
} from '@clanhall-sdk/model/models';
import { camelCase } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Этот сервис является исключением из правила использования запросов только в NGXS.
 */

@Injectable()
export class UnitChildParamsDirectApiService {
  constructor(protected unitSDKService: UnitSDKService) {}

  public getUnitChildParams(
    params: ReturnChildParamsWithOptionsRequestParams,
  ): Observable<ReturnChildParamsWithOptionsResponseSDKModel[]> {
    return this.unitSDKService.returnChildParamsWithOptions(params);
  }

  public getSearchChildParams(
    params: ReturnSearchChildParamsWithOptionsRequestParams,
  ): Observable<ReturnSearchChildParamsWithOptionsResponseSDKModel[]> {
    return this.unitSDKService.returnSearchChildParamsWithOptions(params).pipe(
      map((response) => {
        // #camelCase
        return response.map((param) => {
          if (param.key) {
            // eslint-disable-next-line no-param-reassign
            param.key = camelCase(param.key);
          }
          return param;
        });
      }),
    );
  }

  public getGameChildParamsInSeach(
    params: ReturnUnitParamsWithOptionsRequestParams,
  ): Observable<SearchParameterSDKModel[]> {
    return this.unitSDKService.returnUnitParamsWithOptions(params).pipe(
      map((response) => {
        // #camelCase
        return response.map((param) => {
          if (param.key) {
            // eslint-disable-next-line no-param-reassign
            param.key = camelCase(param.key);
          }
          return param;
        });
      }),
    );
  }
}
