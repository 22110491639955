import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WindowStoreService } from '@shared/services/window.store.service';
import { AuthState } from '@store/system-states/auth/auth.state';
import { SidebarsState, SidebarProp } from '@store/ux-states/sidebars/sidebars.state';
import { Observable } from 'rxjs';

@Injectable()
export class LeftSidebarStoreService extends WindowStoreService {
  @Select(SidebarsState.leftSidebar)
  public leftSidebar$: Observable<SidebarProp>;

  @Select(AuthState.isLoggedIn)
  public isLoggedIn$: Observable<boolean>;

  constructor(protected store: Store) {
    super(store);
  }
}
