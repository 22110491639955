import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { THEME_LS_KEY } from '@shared/constants/theme.constants';
import { ThemeEnum } from '@shared/enums/theme.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { pairwise } from 'rxjs/operators';

// TODO: create state
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private currentThemeBS: BehaviorSubject<ThemeEnum> = new BehaviorSubject<ThemeEnum>(
    this.themeLS || ThemeEnum.main,
  );

  public currentTheme$: Observable<ThemeEnum> = this.currentThemeBS.asObservable();

  public get currentTheme(): ThemeEnum {
    return this.currentThemeBS.getValue();
  }

  public set currentTheme(theme: ThemeEnum) {
    this.currentThemeBS.next(theme);
    this.themeLS = theme;
  }

  private get themeLS(): ThemeEnum | undefined {
    return (localStorage.getItem(THEME_LS_KEY) as ThemeEnum) || ThemeEnum.main;
  }

  private set themeLS(theme: ThemeEnum) {
    localStorage.setItem(THEME_LS_KEY, theme);
  }

  public init(overlayContainer: OverlayContainer): void {
    overlayContainer.getContainerElement().classList.add(this.themeLS);
    document.body.classList.add(this.themeLS);

    this.currentTheme$.pipe(pairwise()).subscribe(([oldTheme, currentTheme]) => {
      overlayContainer.getContainerElement().classList.replace(oldTheme, currentTheme);
      document.body.classList.replace(oldTheme, currentTheme);
    });
  }
}
