import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Header } from '@store/ux-states/header/header.actions';
import { AuthState } from '@store/system-states/auth/auth.state';
import { Observable } from 'rxjs';
import { AuthRoutesEnum } from '@modules/auth/auth-routing.constants';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddDiscordModalComponent } from '@components/add-discord-modal/add-discord-modal.component';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  @Select(AuthState.isLoggedIn)
  public isLoggedIn$: Observable<boolean>;

  public authRoutesEnum: typeof AuthRoutesEnum = AuthRoutesEnum;

  constructor(private store: Store, private matDialog: MatDialog) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.store.dispatch(new Header.ChangeTitle('NO_MODULE_COMPONENTS.WELCOME.HELLO_TITLE'));
    });

    this.showDiscordAttachDialog();
  }

  private showDiscordAttachDialog(): void {
    const loggedIn: boolean = this.store.selectSnapshot(AuthState.isLoggedIn);
    const isDiscordAttached = this.store.selectSnapshot(AuthState.discordIsAttached);

    if (loggedIn && !isDiscordAttached) {
      this.matDialog.open(AddDiscordModalComponent);
    }
  }
}
