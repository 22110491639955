import { Injectable } from '@angular/core';
import { UnitChildParamsDirectApiService } from '@modules/units/shared/services/unit-child-params-direct-api.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  BaseSearchUnitsState,
  BasicSearchUnitStateModel,
  getBasicSearchUnitStateModelDefaults,
  UserSelectSearchParameterSDKModel,
} from '@store/common-states/search/shared/base/base-search-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import {
  CharSDKService,
  ReturnCharListRequestParams,
  SearchCharsRequestParams,
} from '@clanhall-sdk/api/char.sdk.service';
import { CharInListSDKModel } from '@clanhall-sdk/model/charInList.sdk.model';
import { CharListSDKModel } from '@clanhall-sdk/model/charList.sdk.model';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SearchCharsUnitsActions } from './search-chars-units.actions';

export interface CharsSearchUnitsStateModel extends BasicSearchUnitStateModel {
  units: CharInListSDKModel[];
  tableParams: Omit<CharListSDKModel, 'data'>;
}

const defaults: CharsSearchUnitsStateModel = {
  ...getBasicSearchUnitStateModelDefaults(),
  units: [],
};

@State<CharsSearchUnitsStateModel>({
  name: 'searchChars',
  defaults,
})
@Injectable()
export class SearchCharsUnitsState extends BaseSearchUnitsState {
  protected titles = {
    ...this.basicTitles,
  };

  constructor(
    private store: Store,
    private charSDKService: CharSDKService,
    private directUnitApiService: UnitChildParamsDirectApiService,
  ) {
    super();
  }

  @Selector()
  static loading(state: CharsSearchUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: CharsSearchUnitsStateModel): CharInListSDKModel[] {
    return state.units;
  }

  @Selector()
  static tableParams(state: CharsSearchUnitsStateModel): Omit<CharListSDKModel, 'data'> {
    return state.tableParams;
  }

  @Selector()
  static tableDataParams(state: CharsSearchUnitsStateModel): TableParameterSDKModel[] {
    return state.tableDataParams;
  }

  @Selector()
  static searchParams(state: CharsSearchUnitsStateModel): UserSelectSearchParameterSDKModel[] {
    return state.searchParams;
  }

  @Action(SearchCharsUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<CharsSearchUnitsStateModel>,
    { title }: SearchCharsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return super.changeTitleBase(context, title);
  }

  @Action(SearchCharsUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<CharsSearchUnitsStateModel>): Observable<void> {
    return super.loadingEndBase(context);
  }

  @Action(SearchCharsUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<CharsSearchUnitsStateModel>): Observable<void> {
    return super.loadingStartBase(context);
  }

  @Action(SearchCharsUnitsActions.UpdatePage)
  public updatePage(
    context: StateContext<CharsSearchUnitsStateModel>,
    payload: SearchCharsUnitsActions.UpdatePage,
  ): Observable<void> {
    return super.updatePageBase(context, payload, new SearchCharsUnitsActions.Search());
  }

  @Action(SearchCharsUnitsActions.QuerySearchParams)
  public querySearchParams(context: StateContext<CharsSearchUnitsStateModel>): Observable<void> {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);

    return super.querySearchParamsBase(
      context,
      currentTitle,
      (requestParameters: ReturnCharListRequestParams) =>
        this.charSDKService.returnCharList(requestParameters),
      defaults,
    );
  }

  @Action(SearchCharsUnitsActions.ResetSearch)
  public resetSearch({ getState, patchState, dispatch }: StateContext<CharsSearchUnitsStateModel>) {
    const gameSearchParam = getState().searchParams.find((param) => param.key === 'game');

    return this.directUnitApiService
      .getGameChildParamsInSeach({
        gameId: gameSearchParam.userSelect as number,
        unitType: 'char',
      })
      .pipe(
        switchMap((responseParams) => {
          patchState({
            searchParams: [
              ...responseParams.map((param) => {
                return {
                  ...param,
                  userSelect: null,
                };
              }),
              gameSearchParam,
            ],
          });
          return dispatch(new SearchCharsUnitsActions.Search());
        }),
      );
  }

  @Action(SearchCharsUnitsActions.Search)
  public search(
    context: StateContext<CharsSearchUnitsStateModel>,
    payload: SearchCharsUnitsActions.Search,
  ): Observable<void> {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);
    return super.searchBase(
      context,
      payload,
      (requestParameters: SearchCharsRequestParams) =>
        this.charSDKService.searchChars(requestParameters),
      currentTitle,
    );
  }

  @Action(SearchCharsUnitsActions.AddNewChildParams)
  public addNewChildParams(
    context: StateContext<CharsSearchUnitsStateModel>,
    payload: SearchCharsUnitsActions.AddNewChildParams,
  ): Observable<void> {
    return super.addNewChildParams(context, payload);
  }
}
