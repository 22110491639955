import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthRoutesEnum } from '@modules/auth/auth-routing.constants';
import { TranslateService } from '@ngx-translate/core';
import { MyProfileRoutesService } from '@shared/services/my-profile-routes.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { Unsubscriber } from '@shared/services/unsubscriber.service';
import { SidebarProp } from '@store/ux-states/sidebars/sidebars.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SettingsRoutesEnum } from '@modules/settings/settings-routing.constants';
import { RightSidebarStoreService } from './right-sidebar.store.service';

@Component({
  selector: 'app-right-menu',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Unsubscriber, RightSidebarStoreService],
})
export class RightMenuComponent implements OnInit {
  public rightSidebar$: Observable<SidebarProp> = this.rightSidebarStoreService.rightSidebar$;

  public userName$: Observable<string> = this.rightSidebarStoreService.userName$;

  public isLoggedIn$: Observable<boolean> = this.rightSidebarStoreService.isLoggedIn$;

  public authRoutesEnum: typeof AuthRoutesEnum = AuthRoutesEnum;

  public settingsRoutesEnum: typeof SettingsRoutesEnum = SettingsRoutesEnum;

  constructor(
    private sanitizer: DomSanitizer,
    private rightSidebarStoreService: RightSidebarStoreService,
    private sidebarService: SidebarService,
    private translateService: TranslateService,
    private unsubscriber: Unsubscriber,
    private myProfileRoutesService: MyProfileRoutesService,
  ) {}

  public ngOnInit(): void {
    this.initProfileTextObserver();
    this.initSignInTextObserver();
  }

  public closeSidebar(): void {
    this.sidebarService.closeRight();
  }

  private initProfileTextObserver(): void {
    combineLatest([this.userName$, this.isLoggedIn$, this.myProfileRoutesService.myProfileLink$])
      .pipe(
        filter(
          ([userName, isLoggedIn, myProfileLink]) => !!isLoggedIn && !!userName && !!myProfileLink,
        ),
        takeUntil(this.unsubscriber.destroy$),
      )
      .subscribe(([userName, isLoggedIn, myProfileLink]) => {
        if (isLoggedIn && userName) {
          const text: string = `<a href="${myProfileLink}">${userName}</a>`;
          this.rightSidebarStoreService.changeHeaderRightText(
            this.sanitizer.bypassSecurityTrustHtml(text),
          );
        }
      });
  }

  private initSignInTextObserver(): void {
    combineLatest([
      this.isLoggedIn$,
      this.translateService.get('NO_MODULE_COMPONENTS.SIDEBARS.RIGHT.SIGNIN'),
    ])
      .pipe(
        filter((responses) => {
          const isLoggedIn: boolean = responses[0];
          return !isLoggedIn;
        }),
        takeUntil(this.unsubscriber.destroy$),
      )
      .subscribe((responses) => {
        const regTextTranslate: string | undefined = responses[1];
        const text: string = `<a href="${AuthRoutesEnum.getFullLink(
          AuthRoutesEnum.registration,
        )}">${regTextTranslate}</a>`;

        this.rightSidebarStoreService.changeHeaderRightText(
          this.sanitizer.bypassSecurityTrustHtml(text),
        );
      });
  }
}
