/* eslint-disable max-classes-per-file */
import { Directive, HostListener, NgModule } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      this.router.navigate([event.target.getAttribute('href')]);
      event.preventDefault();
    }
  }
}

@NgModule({
  declarations: [RouteTransformerDirective],
  exports: [RouteTransformerDirective],
})
export class RouteTransformerDirectiveModule {}
