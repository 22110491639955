import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SidebarObserverService } from '@shared/services/observer/sidebar-observer.service';
import { Sidebars } from '@store/ux-states/sidebars/sidebars.actions';
import {
  SidebarProp,
  SidebarsState,
  SidebarsStateModel,
} from '@store/ux-states/sidebars/sidebars.state';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { WindowStoreService } from './window.store.service';

@Injectable()
export class SidebarService extends WindowStoreService {
  @Select(SidebarsState.leftSidebar)
  public readonly leftSidebar$: Observable<SidebarProp>;

  @Select(SidebarsState.rightSidebar)
  public readonly rightSidebar$: Observable<SidebarProp>;

  @Select(SidebarsState.hasBackdrop)
  public readonly hasBackdrop$: Observable<boolean>;

  private leftSidebarStyle$: Observable<{ [key: string]: string }>;

  private rightSidebarStyle$: Observable<{ [key: string]: string }>;

  constructor(private sidebarObserverService: SidebarObserverService, protected store: Store) {
    super(store);
  }

  public get isRightSidebarFull(): boolean {
    return this.store.selectSnapshot<SidebarsStateModel>((state) => state.sidebars).right.isFull;
  }

  public get isLeftSidebarFull(): boolean {
    return this.store.selectSnapshot<SidebarsStateModel>((state) => state.sidebars).left.isFull;
  }

  public closeRight(): void {
    if (this.isMobile) {
      this.sidebarObserverService.rightSidebar.close();
    } else if (this.isRightSidebarFull && this.isMiddle) {
      this.changeRightSidebarViewState(false);
    }
  }

  public toggleRight(): void {
    this.sidebarObserverService.rightSidebar.toggle();
  }

  public closeLeft(): void {
    if (this.isMobile) {
      this.sidebarObserverService.leftSidebar.close();
    } else if (this.isLeftSidebarFull && this.isMiddle) {
      this.changeLeftSidebarViewState(false);
    }
  }

  public toggleLeft(): void {
    this.sidebarObserverService.leftSidebar.toggle();
  }

  public closeSidebars(): void {
    this.store.dispatch(new Sidebars.CloseSidebars());
  }

  public initSidebars(
    sidebarLeft,
    sidebarRight,
  ): Observable<Observable<{ [key: string]: string }>[]> {
    this.sidebarObserverService.initObserver(sidebarLeft, sidebarRight).subscribe();

    this.leftSidebarStyle$ = this.createSidebarSizeObs(this.leftSidebar$);
    this.rightSidebarStyle$ = this.createSidebarSizeObs(this.rightSidebar$);

    return of<Observable<{ [key: string]: string }>[]>([
      this.createSidebarSizeObs(this.leftSidebar$),
      this.createSidebarSizeObs(this.rightSidebar$),
      this.createWrapperStyleObs(),
    ]);
  }

  public changeLeftSidebarViewState(value: boolean): Observable<void> {
    return this.store.dispatch(new Sidebars.ChangeLeftSidebarViewState(value));
  }

  public changeRightSidebarViewState(value: boolean): Observable<void> {
    return this.store.dispatch(new Sidebars.ChangeRightSidebarViewState(value));
  }

  private createSidebarSizeObs(sidebar$: Observable<SidebarProp>): Observable<{ width: string }> {
    const sidebarSize = {
      full: { width: '250px' },
      mini: { width: '65px' },
    };

    return combineLatest([this.isMiddle$, sidebar$]).pipe(
      map(([isMiddle, sidebar]) => {
        return !isMiddle || sidebar.isFull;
      }),
      map((value: boolean) => {
        return value ? sidebarSize.full : sidebarSize.mini;
      }),
    );
  }

  public toggleRightMiddleSidebar(): void {
    const stateSnapshot: SidebarsStateModel = this.store.selectSnapshot<SidebarsStateModel>(
      (state) => state.sidebars,
    );

    this.store.dispatch(new Sidebars.ChangeRightSidebarViewState(!stateSnapshot.right.isFull));
  }

  public toggleLeftMiddleSidebar(): void {
    const stateSnapshot: SidebarsStateModel = this.store.selectSnapshot<SidebarsStateModel>(
      (state) => state.sidebars,
    );

    this.store.dispatch(new Sidebars.ChangeLeftSidebarViewState(!stateSnapshot.left.isFull));
  }

  private createWrapperStyleObs(): Observable<{ [key: string]: string }> {
    return combineLatest(
      this.leftSidebarStyle$,
      this.rightSidebarStyle$,
      this.isMobile$,
      this.isMiddle$,
      this.leftSidebar$,
      this.rightSidebar$,
    ).pipe(
      debounceTime(50),
      map(([left, right, isMobile, isMiddle, leftSidebar, rightSidebar]) => {
        const getWidth: (sidebarStyle: { width: string }) => number = ({ width }) => {
          return Number(width.replaceAll(/\D/g, ''));
        };
        if (
          isMiddle &&
          ((leftSidebar.mode === 'over' && leftSidebar.isFull) ||
            (rightSidebar.mode === 'over' && rightSidebar.isFull))
        ) {
          return {
            width: 'calc(100% - 128px)',
            marginRight: '65px',
            marginLeft: '65px',
          };
        }
        return {
          width: !isMobile
            ? `calc(100% - ${getWidth(left as any) + getWidth(right as any)}px)`
            : '100%',

          marginLeft: !isMobile ? left.width : '0',
          marginRight: !isMobile ? right.width : '0',
        };
      }),
    );
  }
}
