import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Select, Store } from '@ngxs/store';
import { AbstractObserverService } from '@shared/services/observer/abstract-observer.service';
import { Sidebars } from '@store/ux-states/sidebars/sidebars.actions';
import { SidebarsState, SidebarsStateModel } from '@store/ux-states/sidebars/sidebars.state';
import { WindowState } from '@store/ux-states/window/window.state';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SidebarObserverService implements AbstractObserverService {
  public leftSidebar: MatSidenav = undefined;

  public rightSidebar: MatSidenav = undefined;

  @Select(SidebarsState)
  private sidebars$: Observable<SidebarsStateModel>;

  @Select(WindowState.isMobile)
  private isMobile$: Observable<boolean>;

  @Select(WindowState.isMiddle)
  private isMiddle$: Observable<boolean>;

  @Select(WindowState.isLarge)
  private isLarge$: Observable<boolean>;

  constructor(private store: Store) {}

  public initObserver(left: MatSidenav, right: MatSidenav): Observable<void> {
    this.leftSidebar = left;
    this.rightSidebar = right;

    this.isMobile$.pipe(filter((v) => !!v)).subscribe(() => {
      this.store.dispatch(new Sidebars.ChangeLeftSidebarMode('over'));
      this.store.dispatch(new Sidebars.ChangeRightSidebarMode('over'));

      if (this.leftSidebar.opened) this.leftSidebar.close();
      if (this.rightSidebar.opened) this.rightSidebar.close();

      this.store.dispatch(new Sidebars.ChangeLeftSidebarViewState(true));
      this.store.dispatch(new Sidebars.ChangeRightSidebarViewState(true));
    });

    this.isMiddle$.pipe(filter((v) => !!v)).subscribe(() => {
      this.store.dispatch(new Sidebars.ChangeLeftSidebarMode('over'));
      this.store.dispatch(new Sidebars.ChangeRightSidebarMode('over'));

      if (!this.leftSidebar.opened) this.leftSidebar.open();
      if (!this.rightSidebar.opened) this.rightSidebar.open();

      this.store.dispatch(new Sidebars.ChangeLeftSidebarViewState(false));
      this.store.dispatch(new Sidebars.ChangeRightSidebarViewState(false));
    });

    this.isLarge$.pipe(filter((v) => !!v)).subscribe(() => {
      this.store.dispatch(new Sidebars.ChangeLeftSidebarMode('side'));
      this.store.dispatch(new Sidebars.ChangeRightSidebarMode('side'));

      if (!this.rightSidebar.opened) this.leftSidebar.open();
      if (!this.rightSidebar.opened) this.rightSidebar.open();

      this.store.dispatch(new Sidebars.ChangeLeftSidebarViewState(true));
      this.store.dispatch(new Sidebars.ChangeRightSidebarViewState(true));
    });

    this.sidebars$.subscribe((sidebars) => {
      this.store.dispatch(new Sidebars.ChangeBackdropStatus(this.backdropMatcher(sidebars)));
    });

    return of<void>();
  }

  private backdropMatcher(sidebars: SidebarsStateModel): boolean {
    if (this.store.selectSnapshot(WindowState.isMiddle)) {
      return !!(sidebars.left.isFull || sidebars.right.isFull);
    }
    return !!this.store.selectSnapshot(WindowState.isMobile);
  }
}
