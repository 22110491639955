import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthState } from '@store/system-states/auth/auth.state';
import { AuthRoutesEnum } from '@modules/auth/auth-routing.constants';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const loggedIn: boolean = this.store.selectSnapshot(AuthState.isLoggedIn);
    if (!loggedIn) {
      this.router.navigateByUrl(AuthRoutesEnum.getFullLink(AuthRoutesEnum.login)).then();
    }

    return loggedIn;
  }
}
