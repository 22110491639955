export const AUTH_ROUTE = 'auth';

export enum AuthRoutesEnum {
  registration = 'registration',
  login = 'login',
  logout = 'logout',
}

export namespace AuthRoutesEnum {
  export function getFullLink(route: AuthRoutesEnum): string {
    return `/${AUTH_ROUTE}/${route}`;
  }
}
