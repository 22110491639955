import { Routes } from '@angular/router';
import { MY_DATA_PARAM } from '@shared/constants/common-routing.constants';
import { MyProfileRoutesEnum } from '@modules/my/my-routing.constants';

export const routes: Routes = [
  {
    path: MyProfileRoutesEnum.chars,
    data: { [MY_DATA_PARAM]: true },
    loadChildren: () => import('./my-chars/my-chars.module').then((m) => m.MyCharsModule),
  },
  {
    path: MyProfileRoutesEnum.comms,
    data: { [MY_DATA_PARAM]: true },
    loadChildren: () => import('./my-comms/my-comms.module').then((m) => m.MyCommsModule),
  },
  {
    path: MyProfileRoutesEnum.guilds,
    data: { [MY_DATA_PARAM]: true },
    loadChildren: () => import('./my-guilds/my-guilds.module').then((m) => m.MyGuildsModule),
  },
];
