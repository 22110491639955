import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { UnitRouteEnum } from '@shared/constants/app-routing.constants';
import { UserUnitState } from '@store/common-states/units/user/user-unit-state.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyProfileRoutesService {
  @Select(UserUnitState.myUnitsIds)
  private myProfileIdState: Observable<number[]>;

  private myProfileLinkBSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  public myProfileLink$: Observable<string> = this.myProfileLinkBSubject.asObservable();

  constructor() {
    this.myProfileIdState.subscribe((ids: number[]) => {
      this.myProfileLinkBSubject.next(UnitRouteEnum.unitById(UnitRouteEnum.user, Number(ids[0])));
    });
  }
}
