import { AppConfigStateModel } from '@store/system-states/app-config/app-config.state';

export namespace AppConfigActions {
  export class GetAppConfig {
    public static readonly type: string = '[AppConfig] Get appliation configuration.';
  }

  export class SetAppConfig {
    public static readonly type: string = '[AppConfig] Set appliation configuration.';

    constructor(public payload: AppConfigStateModel['cmsSettings']) {}
  }
}
