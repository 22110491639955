<a
  (click)="closeSidebar()"
  *ngIf="isMobile$ | async"
  class="left-menu-logo-container"
  routerLink="/"
>
  <img alt="ClanHall" class="left-menu-logo-container__logo" src="/assets/logo/logo@192.png" />
</a>
<mat-expansion-panel
  (expandedChange)="changeSearchCollapseState($event)"
  [expanded]="isSearchCollapsed$ | async"
  class="mat-elevation-z0"
>
  <mat-expansion-panel-header *ngIf="(leftSidebar$ | async).isFull">
    <mat-nav-list>
      <mat-list-item disableRipple>
        <mat-icon matListIcon>search</mat-icon>
        <a [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.FIND' | translate" matLine></a>
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <mat-nav-list *ngIf="(leftSidebar$ | async).isFull">
    <ng-container *ngFor="let menuItem of searchMenu">
      <mat-list-item
        (click)="closeSidebar()"
        *ngIf="menuItem.ngIfAsync | async"
        [routerLink]="menuItem.src"
      >
        <mat-icon matListIcon>{{ menuItem.icon }}</mat-icon>
        <a [innerHTML]="menuItem.text | translate" matLine></a>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</mat-expansion-panel>
<mat-divider *ngIf="(leftSidebar$ | async).isFull"></mat-divider>
<mat-nav-list>
  <mat-list-item (click)="openLeftSidebar()" *ngIf="!(leftSidebar$ | async).isFull" disableRipple>
    <mat-icon matListIcon>search</mat-icon>
    <a [innerHTML]="'NO_MODULE_COMPONENTS.SIDEBARS.LEFT.FIND' | translate" matLine></a>
  </mat-list-item>
  <mat-divider *ngIf="!(leftSidebar$ | async).isFull"></mat-divider>
  <ng-container *ngFor="let menuItem of accountMenu">
    <mat-list-item
      (click)="closeSidebar()"
      *ngIf="menuItem.ngIfAsync | async"
      [routerLink]="menuItem.src || (menuItem.srcAsync | async)"
    >
      <mat-icon aria-hidden="true" matListIcon>{{ menuItem.icon }}</mat-icon>
      <a *ngIf="(leftSidebar$ | async).isFull" [innerHTML]="menuItem.text | translate" matLine></a>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
<mat-divider></mat-divider>
