import { STATE_TYPE } from '@store/common-states/search/shared/base/action-base-types';
import { UserSelectSearchParameterSDKModel } from '@store/common-states/search/shared/base/base-search-units.state.directive';
import { IAddNewChildParams } from '../interfaces/add-new-child-params.interface';
import { PageEvent } from '@angular/material/paginator';

const ACTION_TYPE_DUMB = '...';

export namespace SearchUnitsBase {
  export class LoadingStart {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.loading.start;
  }

  export class LoadingEnd {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.loading.end;
  }

  export class ChangeTitle {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.title.change;

    constructor(public title: string) {}
  }

  export class UpdatePage {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.updatePage;

    constructor(public page: PageEvent) {}
  }

  export class QuerySearchParams {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.get.searchParams;
  }

  export class ResetSearch {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.resetSearch;
  }

  export class Search {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.search;

    constructor(
      public searchParams?: Pick<UserSelectSearchParameterSDKModel, 'userSelect' | 'key'>[],
      public pageIndex?: number,
    ) {}
  }

  export class AddNewChildParams {
    static readonly type: string = ACTION_TYPE_DUMB + STATE_TYPE.addNewChildParams;

    constructor(
      public currentSelectedParams: IAddNewChildParams['currentSelectedParams'],
      public newChildParams: IAddNewChildParams['newChildParams'],
    ) {}
  }
}
