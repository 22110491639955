import { Injectable } from '@angular/core';
import { MyUnitSDKService } from '@clanhall-sdk/api/myUnit.sdk.service';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { undefined$ } from '@shared/functions/void-observable';
import { BaseUnitsState } from '@store/common-states/units/shared/base/base-units.state';
import {
  BaseUnitStateModel,
  getBaseUnitModel,
} from '@store/common-states/units/shared/base/base-units.state.directive';
import { UserUnitActions } from '@store/common-states/units/user/user-unit.actions';
import { HeaderState } from '@store/ux-states/header/header.state';
import { UserSDKService } from '@clanhall-sdk/api/user.sdk.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserUnitStateModel extends BaseUnitStateModel {}

const defaults: UserUnitStateModel = {
  ...getBaseUnitModel(),
};

@State<UserUnitStateModel>({
  name: 'profiles',
  defaults,
})
@Injectable()
export class UserUnitState extends BaseUnitsState {
  private titles = {
    loadUnit: 'Загружаем персонажа',
    saveUnit: 'Сохраняем персонажа',
  };

  constructor(
    private store: Store,
    private userSDKService: UserSDKService,
    protected myUnitSDKService: MyUnitSDKService,
  ) {
    super(myUnitSDKService);
  }

  @Selector()
  static loading(state: UserUnitStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: UserUnitStateModel) {
    return state.loadedUnits;
  }

  @Selector()
  public static myUnitsIds(state: UserUnitStateModel) {
    return this.filterOnlyMyIds(state.loadedUnits);
  }

  static unit(id: number) {
    return createSelector([UserUnitState], (state: UserUnitStateModel) => {
      return state.loadedUnits[id];
    });
  }

  @Action(UserUnitActions.LoadingStart)
  public loadingStart(context: StateContext<UserUnitStateModel>): Observable<void> {
    return this.loadingStartBase(context);
  }

  @Action(UserUnitActions.LoadingEnd)
  public loadingEnd(context: StateContext<UserUnitStateModel>): Observable<void> {
    return this.loadingEndBase(context);
  }

  @Action(UserUnitActions.ChangeTitle)
  public changeTitle(
    ctx: StateContext<UserUnitStateModel>,
    { title }: UserUnitActions.ChangeTitle,
  ): Observable<void> {
    return this.changeTitleBase(ctx, title);
  }

  @Action(UserUnitActions.SaveUnitParams)
  public saveUnitParams(
    context: StateContext<UserUnitStateModel>,
    payload: UserUnitActions.SaveUnitParams,
  ): Observable<void> {
    return this.saveUnitParamsBase(
      context,
      payload,
      'user',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    ).pipe(
      tap(() => {
        window.document.location.reload();
      }),
    );
  }

  @Action(UserUnitActions.SaveUnitAvatar)
  public saveUnitAvatar(
    context: StateContext<UserUnitStateModel>,
    payload: UserUnitActions.SaveUnitAvatar,
  ): Observable<void> {
    return this.saveUnitAvatarBase(
      context,
      payload,
      'user',
      this.store.selectSnapshot(HeaderState.title),
      this.titles.saveUnit,
    );
  }

  @Action(UserUnitActions.QueryUnitById)
  public queryUnitById(
    context: StateContext<UserUnitStateModel>,
    payload: UserUnitActions.QueryUnitById,
  ): Observable<void> {
    return this.queryUnitByIdBase(
      context,
      payload.unitId,
      this.store.selectSnapshot(HeaderState.title),
      this.titles.loadUnit,
      () => this.userSDKService.returnUserProfile({ id: payload.unitId }),
    );
  }

  @Action(UserUnitActions.AddMyUserId)
  public addMyUserId(
    context: StateContext<UserUnitStateModel>,
    { userId }: UserUnitActions.AddMyUserId,
  ): Observable<void> {
    const state: UserUnitStateModel = context.getState();
    context.patchState({
      ...state,
      loadedUnits: {
        ...state.loadedUnits,
        [userId]: {
          isMy: true,
          params: [],
          actions: [],
        },
      },
    });
    return undefined$();
  }
}
