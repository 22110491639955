import { RestoreGuildRequestSDKModel } from '@clanhall-sdk/model/restoreGuildRequest.sdk.model';
import { STATE_TYPE } from '@store/common-states/units/shared/base/base-units.state.directive';
import {
  AddMyUnitBase,
  ChangeTitleBase,
  DeleteUnitBase,
  LoadingEndBase,
  LoadingStartBase,
  QueryMyArchivedUnitsListBase,
  QueryUnitByIdBase,
  QueryUnitsListBase,
  RestoreUnitBase,
  SaveUnitAvatarBase,
  SaveUnitParamsBase,
} from '@store/common-states/units/shared/base/shared-units.actions';
import {
  AddGuildRequestParams,
  RestoreMyGuildProfileRequestParams,
  ReturnMyCharListByGameRequestParams,
  ReturnMyCharListByGuildRequestParams,
} from '@clanhall-sdk/api/myGuild.sdk.service';

const ACTIONS_NAME = '[GuildsUnits] ';

export namespace GuildsUnitsActions {
  export class LoadingStart extends LoadingStartBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.start;
  }

  export class LoadingEnd extends LoadingEndBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.end;
  }

  export class ChangeTitle extends ChangeTitleBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.title.change;
  }

  export class QueryUnitById extends QueryUnitByIdBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.unitById;
  }

  export class SaveUnitParams extends SaveUnitParamsBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitParams;
  }

  export class SaveUnitAvatar extends SaveUnitAvatarBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitAvatar;
  }

  export class QueryMyUnitsList extends QueryUnitsListBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.myUnitsList;
  }

  export class QueryMyArchivedUnitsList extends QueryMyArchivedUnitsListBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.myArchivedUnitsList;
  }

  export class DeleteUnit extends DeleteUnitBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.deleteUnit;
  }

  export class RestoreUnit extends RestoreUnitBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.restoreUnit;

    constructor(
      public unitId: RestoreMyGuildProfileRequestParams['id'],
      public charId: RestoreGuildRequestSDKModel['charId'],
    ) {
      super(unitId);
    }
  }

  export class GetCharsForGuildRestore {
    static readonly type: string = `${ACTIONS_NAME}Get chars for resore guild unit.`;

    constructor(public guildId: ReturnMyCharListByGuildRequestParams['guildId']) {}
  }

  export class AddMyUnit extends AddMyUnitBase<AddGuildRequestParams> {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.addMyUnit;
  }

  export class GetAvailableGamesForCreate {
    static readonly type: string = `${ACTIONS_NAME}Get available games for create guild.`;
  }

  export class GetAvailableCharsForCreate {
    static readonly type: string = `${ACTIONS_NAME}Get chars for create guild.`;

    constructor(public params: ReturnMyCharListByGameRequestParams) {}
  }
}
