export namespace Docs {
  export class UpdateDocsList {
    static readonly type: string = '[ Docs ] Update docs in storage';
  }

  export class GetDocByKey {
    static readonly type: string = '[ Docs ] Get document by document key';

    constructor(public docKey: string) {}
  }
}
