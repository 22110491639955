import { STATE_TYPE } from '@store/common-states/units/shared/base/base-units.state.directive';
import {
  AddMyUnitBase,
  ChangeTitleBase,
  DeleteUnitBase,
  LoadingEndBase,
  LoadingStartBase,
  QueryMyArchivedUnitsListBase,
  QueryUnitByIdBase,
  QueryUnitsListBase,
  RestoreUnitBase,
  SaveUnitAvatarBase,
  SaveUnitParamsBase,
} from '@store/common-states/units/shared/base/shared-units.actions';
import { AddCharRequestParams } from '@clanhall-sdk/api/myChar.sdk.service';

const ACTIONS_NAME = '[CharsUnits] ';

export namespace CharsUnitsActions {
  export class LoadingStart extends LoadingStartBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.start;
  }

  export class LoadingEnd extends LoadingEndBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.loading.end;
  }

  export class ChangeTitle extends ChangeTitleBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.title;
  }

  export class QueryUnitById extends QueryUnitByIdBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.unitById;
  }

  export class SaveUnitParams extends SaveUnitParamsBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitParams;
  }

  export class SaveUnitAvatar extends SaveUnitAvatarBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.myUnitAvatar;
  }

  export class QueryUnitsList extends QueryUnitsListBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.myUnitsList;
  }

  export class QueryArchivedUnitsList extends QueryMyArchivedUnitsListBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.get.myArchivedUnitsList;
  }

  export class AddMyUnit extends AddMyUnitBase<AddCharRequestParams> {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.addMyUnit;
  }

  export class DeleteUnit extends DeleteUnitBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.deleteUnit;
  }

  export class RestoreUnit extends RestoreUnitBase {
    static readonly type: string = ACTIONS_NAME + STATE_TYPE.post.restoreUnit;
  }
}
