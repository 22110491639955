import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  BaseSearchUnitsState,
  BasicSearchUnitStateModel,
  getBasicSearchUnitStateModelDefaults,
  UserSelectSearchParameterSDKModel,
} from '@store/common-states/search/shared/base/base-search-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import {
  CommSDKService,
  ReturnCommListRequestParams,
  SearchCommsRequestParams,
} from '@clanhall-sdk/api/comm.sdk.service';
import { CommInListSDKModel } from '@clanhall-sdk/model/commInList.sdk.model';
import { CommListSDKModel } from '@clanhall-sdk/model/models';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { Observable } from 'rxjs';
import { SearchCommsUnitsActions } from './search-comms-units.actions';

export interface CommsSearchUnitsStateModel extends BasicSearchUnitStateModel {
  units: CommInListSDKModel[];
  tableParams: Omit<CommListSDKModel, 'data'>;
}

const defaults: CommsSearchUnitsStateModel = {
  ...getBasicSearchUnitStateModelDefaults(),
  units: [],
};

@State<CommsSearchUnitsStateModel>({
  name: 'searchComms',
  defaults,
})
@Injectable()
export class SearchCommsUnitsState extends BaseSearchUnitsState {
  protected titles = {
    ...this.basicTitles,
  };

  constructor(protected store: Store, private commSDKService: CommSDKService) {
    super();
  }

  @Selector()
  static loading(state: CommsSearchUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static units(state: CommsSearchUnitsStateModel): CommInListSDKModel[] {
    return state.units;
  }

  @Selector()
  static tableParams(state: CommsSearchUnitsStateModel): Omit<CommListSDKModel, 'data'> {
    return state.tableParams;
  }

  @Selector()
  static tableDataParams(state: CommsSearchUnitsStateModel): TableParameterSDKModel[] {
    return state.tableDataParams;
  }

  @Selector()
  static searchParams(state: CommsSearchUnitsStateModel): UserSelectSearchParameterSDKModel[] {
    return state.searchParams;
  }

  @Action(SearchCommsUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<CommsSearchUnitsStateModel>,
    { title }: SearchCommsUnitsActions.ChangeTitle,
  ): Observable<void> {
    return super.changeTitleBase(context as any, title);
  }

  @Action(SearchCommsUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<CommsSearchUnitsStateModel>): Observable<void> {
    return super.loadingEndBase(context as any);
  }

  @Action(SearchCommsUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<CommsSearchUnitsStateModel>): Observable<void> {
    return super.loadingStartBase(context as any);
  }

  @Action(SearchCommsUnitsActions.UpdatePage)
  public updatePage(
    context: StateContext<CommsSearchUnitsStateModel>,
    payload: SearchCommsUnitsActions.UpdatePage,
  ): Observable<void> {
    return super.updatePageBase(context as any, payload, new SearchCommsUnitsActions.Search());
  }

  @Action(SearchCommsUnitsActions.QuerySearchParams)
  public querySearchParams(context: StateContext<CommsSearchUnitsStateModel>): Observable<void> {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);

    return super.querySearchParamsBase(
      context,
      currentTitle,
      (requestParameters: ReturnCommListRequestParams) =>
        this.commSDKService.returnCommList(requestParameters),
      defaults,
    );
  }

  @Action(SearchCommsUnitsActions.ResetSearch)
  public resetSearch({ dispatch }: StateContext<CommsSearchUnitsStateModel>) {
    return dispatch(SearchCommsUnitsActions.QuerySearchParams);
  }

  @Action(SearchCommsUnitsActions.Search)
  public search(
    context: StateContext<CommsSearchUnitsStateModel>,
    payload: SearchCommsUnitsActions.Search,
  ) {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);
    return super.searchBase(
      context as any,
      payload,
      (requestParameters: SearchCommsRequestParams) =>
        this.commSDKService.searchComms(requestParameters),
      currentTitle,
    );
  }
}
