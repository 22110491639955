<app-width-container>
  <mat-card>
    <div style="display: flex">
      <mat-card-header style="flex-grow: 1">
        <mat-card-title>Возможности Кланхолла</mat-card-title>
        <mat-card-subtitle
          >Мы работаем над улучшением функциональности каждый день
          <mat-icon>precision_manufacturing</mat-icon></mat-card-subtitle
        >
      </mat-card-header>
      <div *ngIf="(isLoggedIn$ | async) === false">
        <button
          mat-flat-button
          color="accent"
          [routerLink]="authRoutesEnum.getFullLink(authRoutesEnum.registration)"
        >
          Регистрация
        </button>
      </div>
    </div>
    <mat-card-content>
      <ol>
        <li>
          Рядовому игроку:
          <ul>
            <li>
              Создать свой публичный профиль игрока и делиться им в интернете.<br />
              Профиль с заполненными данными доступен для поиска другими игроками и лидерами
              сообществ / гильдий.
            </li>
            <li>
              Создать профиль своего персонажа из Archeage, Black Desert или World of Warcraft и
              также делиться им в интернете.<br />
              На данный момент можно создать не более 1го персонажа в каждой игре.<br />
              Профиль персонажа также доступен для поиска по актуальным параметрам.
            </li>
            <li>
              Найти себе подходящее мультиигровое сообщество или гильдию в одной из этих же игр.<br />
              Сейчас гильдий может быть не так много, но постепенно их количество будет возрастать.
            </li>
          </ul>
        </li>
        <li>
          Лидеру мультиигрового сообщества:
          <ul>
            <li>
              Создать публичный профиль сообщества, заполнить информацию о нем и делиться им в
              интернете.<br />
              На данный момент можно создать не более 1го сообщества. Профиль сообщества доступен
              для поиска другими игроками.
            </li>
            <li>
              Искать игроков со схожими интересами и связываться с ними через Дискорд, указанный в
              их профиле.
            </li>
          </ul>
        </li>
        <li>
          Лидеру гильдии:
          <ul>
            <li>
              Создать публичный профиль своей гильдии, заполнить информацию о ней и делиться
              профилем в интернете.<br />
              На данный момент можно создать не более 1й гильдии в каждой игре.<br />
              Заполненный профиль гильдии доступен для поиска другими игроками по актуальным
              параметрам.
            </li>
            <li>
              Искать игроков с подходящими персонажами и связываться с ними через Дискорд для
              дальнейшего рекрутинга.
            </li>
          </ul>
        </li>
      </ol>
    </mat-card-content>
    <mat-card-actions *ngIf="(isLoggedIn$ | async) === false" align="end"> </mat-card-actions>
  </mat-card>
</app-width-container>
