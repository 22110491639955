import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/core/core.module';
import { MAIN_MODULE_COMPONENTS, MAIN_MODULE_IMPORTS } from './app-module.dependencies';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  imports: [...MAIN_MODULE_IMPORTS, CommonModule, AppRoutingModule, CoreModule.forRoot()],
  declarations: [...MAIN_MODULE_COMPONENTS],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
