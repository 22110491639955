export const SETTINGS_ROUTE: string = 'settings';

export enum SettingsRoutesEnum {
  app = 'app',
}

export namespace SettingsRoutesEnum {
  export function getFullLink(route: SettingsRoutesEnum): string {
    return `/${SETTINGS_ROUTE}/${route}`;
  }
}
