import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CharsSearchUnitsStateModel } from '@store/common-states/search/chars/search-chars-units.state';
import {
  BaseSearchUnitsState,
  BasicSearchUnitStateModel,
  getBasicSearchUnitStateModelDefaults,
  UserSelectSearchParameterSDKModel,
} from '@store/common-states/search/shared/base/base-search-units.state.directive';
import { HeaderState } from '@store/ux-states/header/header.state';
import {
  ReturnUserProfileListRequestParams,
  SearchUserProfilesRequestParams,
  UserSDKService,
} from '@clanhall-sdk/api/user.sdk.service';
import { UserProfileInListSDKModel } from '@clanhall-sdk/model/models';
import { TableParameterSDKModel } from '@clanhall-sdk/model/tableParameter.sdk.model';
import { UserProfileListSDKModel } from '@clanhall-sdk/model/userProfileList.sdk.model';
import { Observable } from 'rxjs';
import { SearchUsersUnitsActions } from './search-users-units.actions';

export interface UsersSearchUnitsStateModel extends BasicSearchUnitStateModel {
  units: UserProfileInListSDKModel[];
  tableParams: Omit<UserProfileListSDKModel, 'data'>;
}

const defaults: UsersSearchUnitsStateModel = {
  ...getBasicSearchUnitStateModelDefaults(),
  units: [],
};

@State<UsersSearchUnitsStateModel>({
  name: 'searchUsers',
  defaults,
})
@Injectable()
export class SearchUsersUnitsState extends BaseSearchUnitsState {
  protected titles = {
    ...this.basicTitles,
  };

  constructor(protected store: Store, private userSDKService: UserSDKService) {
    super();
  }

  @Selector()
  public static loading(state: UsersSearchUnitsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static units(state: UsersSearchUnitsStateModel): UserProfileInListSDKModel[] {
    return state.units;
  }

  @Selector()
  public static tableParams(
    state: UsersSearchUnitsStateModel,
  ): Omit<UserProfileListSDKModel, 'data'> {
    return state.tableParams;
  }

  @Selector()
  public static tableDataParams(state: UsersSearchUnitsStateModel): TableParameterSDKModel[] {
    return state.tableDataParams;
  }

  @Selector()
  public static searchParams(
    state: UsersSearchUnitsStateModel,
  ): UserSelectSearchParameterSDKModel[] {
    return state.searchParams;
  }

  @Action(SearchUsersUnitsActions.ChangeTitle)
  public changeTitle(
    context: StateContext<CharsSearchUnitsStateModel>,
    { title }: SearchUsersUnitsActions.ChangeTitle,
  ): Observable<void> {
    return super.changeTitleBase(context as any, title);
  }

  @Action(SearchUsersUnitsActions.LoadingEnd)
  public loadingEnd(context: StateContext<CharsSearchUnitsStateModel>): Observable<void> {
    return super.loadingEndBase(context as any);
  }

  @Action(SearchUsersUnitsActions.LoadingStart)
  public loadingStart(context: StateContext<CharsSearchUnitsStateModel>): Observable<void> {
    return super.loadingStartBase(context as any);
  }

  @Action(SearchUsersUnitsActions.UpdatePage)
  public updatePage(
    context: StateContext<CharsSearchUnitsStateModel>,
    payload: SearchUsersUnitsActions.UpdatePage,
  ): Observable<void> {
    return super.updatePageBase(context as any, payload, new SearchUsersUnitsActions.Search());
  }

  @Action(SearchUsersUnitsActions.ResetSearch)
  public resetSearch(context: StateContext<UsersSearchUnitsStateModel>): Observable<void> {
    return context.dispatch(SearchUsersUnitsActions.QuerySearchParams);
  }

  @Action(SearchUsersUnitsActions.Search)
  public search(
    context: StateContext<UsersSearchUnitsStateModel>,
    payload: SearchUsersUnitsActions.Search,
  ) {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);
    return super.searchBase(
      context as any,
      payload,
      (requestParameters: SearchUserProfilesRequestParams) =>
        this.userSDKService.searchUserProfiles(requestParameters),
      currentTitle,
    );
  }

  @Action(SearchUsersUnitsActions.QuerySearchParams)
  public querySearchParams(context: StateContext<UsersSearchUnitsStateModel>): Observable<void> {
    const currentTitle: string = this.store.selectSnapshot(HeaderState.title);

    return super.querySearchParamsBase(
      context,
      currentTitle,
      (requestParameters: ReturnUserProfileListRequestParams) =>
        this.userSDKService.returnUserProfileList(requestParameters),
      defaults,
    );
  }
}
